import Config from "@/Configuration/Config";
import axios from "axios"

export default {
    adminUrl: Config.apiBaseUrl + "dashboard/getadmindata",
    clientUrl: Config.apiBaseUrl + "dashboard/getdata?clientId=",
    getPmsDataUrl: Config.apiBaseUrl + "dashboard/getpmsdata?clientId=",
    getSuperAdminDataUrl: Config.apiBaseUrl+"dashboard/getsadmindata",
    getadmindata() {
        return axios.get(this.adminUrl);
    },
    getdata(id) {
        return axios.get(this.clientUrl + id)
    },
    getPmsData(clientId) {
        return axios.get(this.getPmsDataUrl + clientId)
    },
    getSuperAdminData(){
        return axios.get(this.getSuperAdminDataUrl);
    }

}