import config from "@/Configuration/Config";
import axios from "axios";

export  default {
    getTrailsUrl: config.apiBaseUrl+"moneytrail/getmoneytrails?clientId=",
    getTrailUrl:config.apiBaseUrl+"moneytrail/getmoneytrail?trailId",
    addTrailUrl:config.apiBaseUrl+"moneytrail/add",
    updateTrailUrl:config.apiBaseUrl+"moneytrail/update",
    softDeleteTrailUrl: config.apiBaseUrl+"moneytrail/softdelete?trailId=",
    harDeleteTrailUrl: config.apiBaseUrl+"moneytrail/harddelete?trailId=",


    getTrails(filter){
        return axios.get(this.getTrailsUrl+filter.clientId+"&contactId="+filter.contactId+"&search="+filter.search+"&page="+filter.currentPage+"&limit="+filter.pageSize);
    },
    getTrailById(id){
        return axios.get(this.getTrailUrl+id);
    },
    addTrail(trail){
        return axios.post(this.addTrailUrl,trail);
    },
    updateTrail(trail){
        return axios.put(this.updateTrailUrl,trail);
    },
    softDelete(id){
        return axios.put(this.softDeleteTrailUrl+id);
    },
    hardDelete(id){
        return axios.delete(this.harDeleteTrailUrl+id);
    },

}