import axios from "axios"
import Config from "@/Configuration/Config";


export default {
    allurl: Config.apiBaseUrl + "property/getproperties?clientId=",
    allproviderpropertiesurl: Config.apiBaseUrl + "property/getproperties?providerId=",
    geturl: Config.apiBaseUrl + "property/getpropertybyid?propertyId=",
    addurl: Config.apiBaseUrl + "property/add",
    addcomplianceproperty: Config.apiBaseUrl + "property/addcomplianceproperty",
    updateurl: Config.apiBaseUrl + "property/update",
    deleteurl: Config.apiBaseUrl + "property/delete?propertyId=",



    all(filter) {
        return axios.get(this.allurl + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getPropertyByProvider(filter) {
        return axios.get(this.allproviderpropertiesurl + filter.providerId+ "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll(clientId) {
        return axios.get(this.getallurl + clientId + "&limit=1500");
    },
    get(propertyId) {
        return axios.get(this.geturl + propertyId);
    },
    add(property) {
        return axios.post(this.addurl, property)
    },
    update(property) {
        return axios.put(this.updateurl, property)
    },
    deleteProperty(id) {
        return axios.delete(this.deleteurl + id)
    }
}