<template>
  <div class="container-fluid">
    <ToolBar :show-bread="false" clear-id="customerId"
             :showSelect="false" :ShowAddBtn="customerCreate" routeName="customer"
             :btnText="getMessageByCode('add_cust') ?? 'Add Customer'">
      <template v-slot:select>
        <el-select v-if="clientId === null || clientId === 0" class="w-100" filterable @change="getCustomers()"
                   v-model="filter.clientId">
          <el-option v-for="item in clients"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id">
            <template #default>
              <div class="row">
                <span class="col-7">{{ item.name}}</span>
                <span class="text-small col-5">{{ item.market }}</span>
              </div>
            </template>
          </el-option>
        </el-select>
        <router-link to="customer">
          <el-button type="primary" class="ms-2" @click="$store.state.pageId =0">Add Customer</el-button>
        </router-link>
      </template>
    </ToolBar>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 col-md-6 mb-2 ms-auto d-flex justify-content-end">
          <el-select v-model="filter.status" @change="getCustomers()" class="w-50">
            <el-option v-for="status in statuses" :key="status.value" :label="status.text"
                       :value="status.value"></el-option>
          </el-select>
          <el-input class="align-self-end ms-2" :placeholder="getMessageByCode('search') ?? 'Search here..'"
                    @clear="getCustomers" v-model="filter.search"
                    @keyup="getCustomers"
                    clearable>
            <template #append>
              <el-button @click="getCustomers">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>

        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <el-table :data="customers"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe @selection-change="handleSelectionChange">
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column :label="getMessageByCode('name') ?? 'First Name'" prop="firstName" min-width="200"
                             sortable>
            </el-table-column>
            <el-table-column :label="getMessageByCode('lastName') ?? 'Last Name'" prop="lastName" min-width="200"/>
            <el-table-column :label="getMessageByCode('email') ?? 'Email'" prop="email" min-width="200"/>
            <el-table-column :label="getMessageByCode('phone') ?? 'Phone'" prop="mobile" min-width="200"/>
            <el-table-column :label="getMessageByCode('signupDate') ?? 'Signup Date'" prop="created_at" min-width="200">
              <template #default="scope">
                <span>{{ getFormattedDate(scope.row.createdDate) }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('synced') ?? 'App Synced'" prop="isActive" min-width="100">
              <template #default="scope">
                <div class="text-center">
                  <n-tag size="small" :bordered="false" type="success" v-if="scope.row.appUserId > 0">
                    Yes
                  </n-tag>
                  <n-tag v-else size="small" :bordered="false" type="error">
                    No
                  </n-tag>
                  <!--                  <el-tooltip content="Sync AppUser" v-else>-->
                  <!--                    <n-tag class="action-btn" @click="takeRow(scope.row, 'sync')" size="small" :bordered="false"-->
                  <!--                           type="warning"-->
                  <!--                    >-->
                  <!--                      +-->
                  <!--                    </n-tag>-->
                  <!--                  </el-tooltip>-->
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions') ?? 'Actions'" width="100">
              <template #default="scope">
                <div class="d-flex justify-content-center">
                  <el-tooltip content="View">
                    <i @click="takeRow(scope.row, 'view')"
                       class="mgc_eye_2_line text-primary action-btn"
                    ></i>
                  </el-tooltip>
                  <el-tooltip content="App Link" v-if="scope.row.appUserId < 1">
                    <i @click="takeRow(scope.row, 'sync')"
                       class="mgc_link_line text-success action-btn ms-2"
                    ></i>
                  </el-tooltip>
                  <el-tooltip content="App Unlink" v-else>
                    <i @click="takeRow(scope.row, 'unlink')"
                       class="mgc_unlink_line text-danger action-btn ms-2"
                    ></i>
                  </el-tooltip>
                  <el-tooltip content="Edit">
                    <i @click="takeRow(scope.row, 'edit')"
                       class="mgc_edit_line text-primary action-btn ms-2"
                    ></i>
                  </el-tooltip>
                  <el-tooltip content="Delete">
                    <i @click="takeRow(scope.row, 'delete')"
                       class="mgc_delete_2_line text-danger action-btn ms-2"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog v-model="unlinkDialog" width="500" title="Unlink AppUser">
      <template #default>
        <span>Are you sure? You want to unlink AppUser from the customer.</span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="unlinkDialog = false">Cancel</el-button>
          <el-button type="primary" class="ms-2" @click="unlink()">Unlink</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" width="500" title="Delete Customer">
      <template #default>
        <span>Are you sure? You want to delete <span class="text-danger fw-semibold">{{ customer.firstName }}</span> customer.</span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteDialog = false">Cancel</el-button>
          <el-button type="danger" class="ms-2" @click="deleteCustomer()">Delete</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="profileDialog" width="500">
      <template #header>
        <span>View Customer</span>
      </template>
      <template #default>
        <n-list show-divider>
          <n-list-item>
            <div class="row">
              <div class="col-12 col-md-6">
                <span>FullName</span>
              </div>
              <div class="col-12 col-md-6">
                <span>{{ customer?.name }}</span>
              </div>


            </div>
          </n-list-item>
          <n-list-item>
            <div class="row">
              <div class="col-12 col-md-6">
                <span>Email</span>
              </div>
              <div class="col-12 col-md-6">
                <span>{{ customer?.email }}</span>
              </div>
            </div>
          </n-list-item>
          <n-list-item>
            <div class="row">
              <div class="col-12 col-md-6">
                <span>Phone</span>
              </div>
              <div class="col-12 col-md-6">
                <span>{{ customer?.phone }}</span>
              </div>
            </div>
          </n-list-item>
          <n-list-item>
            <div class="row">
              <div class="col-12 col-md-6">
                <span>Signup Date</span>
              </div>
              <div class="col-12 col-md-6">
                <span>{{ customer?.createdDate?.toDateString("DD/MMM/YYYY") }}</span>
              </div>
            </div>
          </n-list-item>
        </n-list>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button type="primary" @click="profileDialog = false">Close</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="deactivateDialog" width="500" title="Deactivate Customer">
      <template #default>
        <span>Are you sure? you want to deactivate customer <span
            class="fw-semibold text-warning">{{ customer?.name }}</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deactivateDialog = false">
            Cancel
          </el-button>
          <el-button type="warning" class="ms-2" @click="deactivate(customer?.id)">
            Deactivate
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="activateDialog" width="500" title="Activate Customer">
      <template #default>
        <span>Are you sure? you want to activate customer <span
            class="fw-semibold text-success">{{ customer?.name }}</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="activateDialog = false">
            Cancel
          </el-button>
          <el-button type="success" color="#198754" class="ms-2" @click="activate(customer?.id)">
            Activate
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addDialog" width="650" title="Add Customer" draggable align-center>
      <template #default>
        <el-form label-position="top">
          <div class="row">
            <el-form-item class="col-12" v-if="clientId === null || clientId === 0 || !clientId">
              <template #label>
                <span class="required">{{ getMessageByCode('client') ?? 'Client' }}</span>
              </template>
              <el-select v-model="customer.clientId" filterable class="w-100"
                         placeholder="Select"
              >
                <el-option
                    v-for="item in clients"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-12" label="Company">
              <el-input v-model="customer.company"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" label="First Name">
              <el-input v-model="customer.firstName"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" label="Last Name">
              <el-input v-model="customer.lastName"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" label="Phone">
              <el-input v-model="customer.mobile"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" label="Email">
              <el-input v-model="customer.email"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" label="Home Telephone">
              <el-input v-model="customer.telephone"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" label="Company Name">
              <el-input v-model="customer.companyName"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" label="Date Of Birth">
              <el-date-picker v-model="customer.dob" :editable="false" class="w-100"></el-date-picker>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" label="Address">
              <el-input v-model="customer.address"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addDialog = false">Cancel</el-button>
          <el-button type="primary" @click="addCustomer()">Add Customer</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="syncDialog" title="Sync Customer" width="400">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="Select AppUser">
            <el-select v-model="customer.appUserId" class="w-100">
              <el-option v-for="user in appUsers" :key="user.id" :label="user.name" :value="user.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="syncDialog = false">Cancel</el-button>
          <el-button type="primary" class="ms-2" @click="update()">Sync</el-button>
        </div>
      </template>
    </el-dialog>
    <n-spin v-if="loading" size="small" stroke="#000" :stroke-width="15" description="Loading"
            class="cstm-spin"></n-spin>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getLanguage} from "@/Utility/getLanguage";
import clientService from "@/Services/ComponentsServices/clientService";
import {NSpin} from "naive-ui";
import customerService from "@/Services/ComponentsServices/customerService";
import {ShowMessage} from "@/Utility/Utility";
import {NList, NListItem, NTag} from "naive-ui";
import addCompliance from "@/components/ComponentsDialogs/AddCompliance.vue";
import configService from "@/Services/ComponentsServices/configService";
import moment from "moment";

export default {
  name: "CustomersList",
  computed: {
    addCompliance() {
      return addCompliance
    }
  },
  components: {NTag, NListItem, NList, NSpin, ToolBar},
  data() {
    return {
      loading: false,
      addDialog: false,
      syncDialog: false,
      unlinkDialog: false,
      deleteDialog: false,
      customerCreate: false,
      customerDelete: true,
      profileDialog: false,
      deactivateDialog: false,
      activateDialog: false,
      selectedIds: [],
      translations: [],
      clients: [],
      customers: [],
      syncModel: {
        appUserId: "",
        customerId: 0,
      },
      appUsers: [],
      dateFormat: "DD/MM/YYYY",
      customer: {
        "id": 0,
        "firstName": "",
        "lastName": "",
        "mobile": "",
        "telephone": "",
        "address": "",
        "company": "",
        "clientId": Number(sessionStorage.getItem("clientId")),
        "isActive": true,
        "isDeleted": false,
        "createdDate": new Date(),
        "addedBy": "",
        "email": "",
        "dob": new Date(),
        "modifiedBy": "",
        "modifiedDate": null,
        "appUserId": "",
        "website": "",
        "companyNumber": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "region": "",
        "postCode": "",
        "country": "",
      },
      statuses: [
        {text: "All", value: -1},
        {text: "Active", value: 1},
        {text: "InActive", value: 0},

      ],
      clientId: Number(sessionStorage.getItem("clientId")) ?? 0,
      filter: {
        clientId: Number(sessionStorage.getItem("clientId") ?? 0),
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: -1,
        clientGuid: 0,
      },
    }
  },
  methods: {
    getFormattedDate(date) {
      if (date === null || date === undefined) {
        return "";
      }
      return moment.utc(date).local().format(this.dateFormat);
    },
    takeRow(customer, type) {
      this.customer = JSON.parse(JSON.stringify(customer));
      if (this.customer.appUserId === 0) {
        this.customer.appUserId = "";
      }
      switch (type) {
        case 'edit':
          this.$store.state.pageId = this.customer.id;
          this.$router.push('/customer')
          break;
        case 'unlink':
          this.unlinkDialog = true;
          break;
        case 'deactivate':
          this.deactivateDialog = true;
          break;
        case 'activate':
          this.activateDialog = true;
          break;
        case 'sync':
          this.syncDialog = true;
          break;
        case 'delete':
          this.deleteDialog = true;
          break;
        case 'view':
          this.$store.state.pageId = this.customer.id;
          this.$router.push('/customer')
          break;
      }
    },
    async addCustomer() {
      this.addDialog = false;
      this.loading = true;
      try {
        let response = await customerService.add(this.customer);
        if (response?.data !== null) {
          ShowMessage("success", "Customer Added");
          await this.getCustomers();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Add Customer Failed");
      }
      this.loading = false;
    },
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },

    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getCustomers();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getCustomers();
    },
    async getCustomers() {
      this.loading = true;
      try {
        let response = await customerService.all(this.filter);
        this.customers = response?.data?.items ?? [];
      } catch (e) {
        ShowMessage("error", e?.response?.message ?? "Something went wrong")
      }
      this.loading = false;
    },
    async getClients() {
      try {
        this.loading = true;
        if (Number(sessionStorage.getItem('clientId')) === null || Number(sessionStorage.getItem('clientId')) === 0) {
          let response = await clientService.getAll();
          this.clients = response.data.items ?? [];
          if (this.clients.length > 0) {
            this.filter.clientGuid = this.clients[0].clientGuid;
            this.customer.clientId = this.clients[0].id;
            this.filter.clientId = this.clients[0].id;
          }
        }
        await this.getCustomers();
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async getClientGuid() {
      try {

        let response = await clientService.getGuid(this.filter.clientId);
        await this.getAppUser(response?.data);
      } catch (e) {
        console.log(e);
      }
    },
    async getAppUser(clientGuid) {
      this.loading = true;
      try {
        let response = await customerService.getAppUsers(clientGuid);
        this.appUsers = response?.data;
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },
    async deactivate(id) {
      try {
        this.deactivateDialog = false;
        this.loading = true;
        let response = await customerService.deActivate(id);
        if (response?.data) {
          ShowMessage("success", "Customer Deactivated");
          await this.getCustomers();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async activate(id) {
      try {
        this.activateDialog = false;
        this.loading = true;
        let response = await customerService.activate(id);
        if (response?.data) {
          ShowMessage("success", "Customer Activated");
          await this.getCustomers();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async update() {
      this.syncDialog = false;
      try {
        let response = await customerService.update(this.customer);
        if (response?.data?.appUserId > 0) {
          await this.getCustomers();
          await this.getAppUser();
        }
      } catch (e) {
        console.log(e)
      }
    },
    async unlink() {
      this.loading = true;
      this.unlinkDialog = false;
      try {
        let response = await customerService.unlink(this.customer.id);
        if (response?.data) {
          ShowMessage("success", "Customer Unlinked");
          await this.getCustomers();
        }
      } catch (e) {
        ShowMessage("error", "Customer Unlink failed");
        console.log(e)
      }
      this.loading = false;
    },
    async deleteCustomer() {
      this.loading = true;
      this.deleteDialog = false;
      try {
        let response = await customerService.delete(this.customer.id);
        if (response?.data) {
          ShowMessage("success", "Customer Deleted");
          await this.getCustomers();
        }
      } catch (e) {
        ShowMessage("error", "Customer Unlink failed");
        console.log(e)
      }
      this.loading = false;
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }

        let response3 = await configService.getByKey(id, "DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetCustomer() {
      this.customer = {
        "id": 0,
        "firstName": "",
        "lastName": "",
        "mobile": "",
        "telephone": "",
        "address": "",
        "companyName": "",
        "clientId": Number(sessionStorage.getItem("clientId")),
        "isActive": true,
        "isDeleted": false,
        "createdDate": new Date(),
        "addedBy": "",
        "email": "",
        "dob": new Date(),
        "modifiedBy": "",
        "modifiedDate": null,
        "appUserId": "",
      };
      if (Number(sessionStorage.getItem('clientId')) === null || Number(sessionStorage.getItem('clientId')) === 0) {
        if (this.clients.length > 0) {
          this.customer.clientId = this.clients[0].id;
        }
      }
    }
  },
  async created() {
    await this.getClients();
    await this.getClientGuid();
  }
}
</script>

<style scoped>

</style>