<script>
export default {
  name:"AddMoneyTrail"
}
</script>

<template>
<div></div>
</template>

<style scoped>

</style>