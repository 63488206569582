<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row py-2 align-items-center">
        <div class="col-12 col-md-8">
          <BreadCrumb :view="edit" :id="clientContact.id" :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link v-if="edit" to="clientcontacts">
            <el-button text bg class="btn btn-light">
              <i class="bi bi-chevron-left me-2"></i>
              <span class="text-capitalize">{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
            </el-button>
          </router-link>
          <el-button v-else @click="checkClient()" text bg class="btn btn-light">
            <i class="bi bi-chevron-left me-2"></i>
            <span class="text-capitalize">{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
          </el-button>
          <el-button v-if="clientEdit && edit" text bg class="border-0 mbtn-primary ms-2" @click="edit = !edit">
            <span class="text-capitalize">{{ getMessageByCode('edit') ?? 'Edit' }}</span>
          </el-button>
          <el-button @click="addClientContact" v-if="clientContact.id < 1 && clientCreate"
                     class="border-0 mbtn-primary ms-2"><span
              class="text-capitalize">{{ getMessageByCode('add_client_cont') ?? 'Add Client Contact' }}</span>
          </el-button>
          <el-button @click="updateClientContact" v-if="clientContact.id > 0 && clientEdit && !edit" :disabled="edit"
                     class="border-0 mbtn-primary ms-2 text-capitalize">
            {{ getMessageByCode('update_client_cont') ?? 'Update Client Contact' }}
          </el-button>
        </div>
      </div>
    </div>

    <div class="container-fluid m-0 p-0">
      <el-tabs type="border-card" class="demo-tabs" v-model="activeTab">
        <el-tab-pane :name="1">
          <template #label>
        <span class="custom-tabs-label">
          <i class="bi bi-person-vcard me-1"></i>
           <span class="text-capitalize">{{ getMessageByCode('details') ?? 'Details' }}</span>
        </span>
          </template>
          <el-form ref="clientForm" label-position="top" hide-required-asterisk :model="clientContact">

            <div class="row my-row">
              <div class="col-12 col-md-4">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0 text-capitalize">{{
                        getMessageByCode('personal') ?? 'Personal'
                      }}</span>
                    <span class="bi bi-building"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item prop="firstName" :rules="formRules.nameRule">
                      <template #label>
                        <span class="required text-capitalize">{{
                            getMessageByCode('firstName') ?? 'First Name'
                          }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="clientContact.firstName"
                          type="text"
                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('lastName') ?? 'Last Name' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="clientContact.lastName"
                          type="text"
                      />
                    </el-form-item>


                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('telephone') ?? 'Telephone' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="clientContact.telephone"
                          type="text"
                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('mobile') ?? 'Mobile' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="clientContact.mobile"
                          type="text"
                      />
                    </el-form-item>
                    <el-form-item prop="email">
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('email') ?? 'Email' + ' 1' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          @keydown.space.prevent
                          v-model="clientContact.email1"
                          type="text"

                      />
                    </el-form-item>


                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card  border-0 shadow-sm  h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0 text-capitalize">{{ getMessageByCode('address') ?? 'Address' }}</span>
                    <span class="bi bi-sign-turn-right"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('address') ?? 'Address' }} 1</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="clientContact.address1"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('address') ?? 'Address' }} 2</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="clientContact.address2"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('address') ?? 'Address' }} 3</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="clientContact.address3"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('address') ?? 'Address' }} 4</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="clientContact.address4"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('town') ?? 'town' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="clientContact.town"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('region') ?? 'Region' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="clientContact.region"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('postcode') ?? 'Postcode' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="clientContact.postCode"
                          type="text"

                      />
                    </el-form-item>

                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0 text-capitalize"
                          style="font-size: 17px">{{ getMessageByCode('profDetails') ?? 'Professional Details' }}</span>
                    <span class="bi bi-phone"></span>

                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form label-position="top">
                      <el-form-item :label="getMessageByCode('market' ?? 'Market')">
                        <el-select v-model="clientContact.marketId" :disabled="edit">
                          <el-option v-for="market in markets" :key="market.id" :label="market.title"
                                     :value="market.id"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item>
                        <template #label>
                        <span class="required text-capitalize">{{
                            getMessageByCode('companyName') ?? 'Company Name'
                          }}</span>
                        </template>
                        <el-input
                            :disabled="edit"
                            v-model="clientContact.companyName"
                            type="text"
                        />
                      </el-form-item>
                      <el-form-item>
                        <template #label>
                          <span class="text-capitalize">{{ getMessageByCode('website') ?? 'Website' }}</span>
                        </template>
                        <el-input
                            :disabled="edit"
                            v-model="clientContact.website"
                            type="text"
                        />
                      </el-form-item>
                      <el-form-item prop="email">
                        <template #label>
                          <span class="text-capitalize">{{ getMessageByCode('email') ?? 'Email' }} 2 </span>
                        </template>
                        <el-input
                            :disabled="edit"
                            @keydown.space.prevent
                            v-model="clientContact.email2"
                            type="text"

                        />
                      </el-form-item>
                      <el-form-item :label="getMessageByCode('type') ?? 'Type'">
                        <n-input-group class="w-100">
                          <el-select v-model="clientContact.typeId" :disabled="edit">
                            <el-option v-for="type in types" :key="type.id" :label="type.title"
                                       :value="type.id">
                              <template #default>
                                <div class="d-flex justify-content-between align-items-center">
                                  <span>{{ type.title }}</span>
                                  <i @click="deleteType(type.id)" class="mgc_delete_2_line text-danger action-btn"></i>
                                </div>
                              </template>
                            </el-option>
                          </el-select>
                          <el-button type="primary" @click="addTypeDialog = true;resetType()">+</el-button>
                        </n-input-group>
                      </el-form-item>
                      <el-form-item label-position="top">
                        <template #label>
                          <div class="d-flex justify-content-between align-items-center">
                            <span class="text-capitalize">{{
                                getMessageByCode('is_allowed') ?? 'Is App Allowed'
                              }}</span>
                            <el-switch
                                :disabled="edit"
                                inline-prompt
                                active-text="Yes"
                                inactive-text="No" v-model="clientContact.isAppAllowed"></el-switch>

                          </div>
                        </template>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 col-md-4">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0 text-capitalize"
                          style="font-size: 17px">{{
                        getMessageByCode('additional') ?? 'Additional'
                      }} {{ getMessageByCode('details') ?? 'Details' }}</span>
                    <span class="bi bi-mortarboard"></span>

                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form label-position="top">
                      <el-form-item>
                        <template #label>
                        <span class="text-capitalize">{{
                            getMessageByCode('qualification') ?? 'Qualification'
                          }}</span>
                        </template>
                        <el-input
                            :disabled="edit"
                            v-model="clientContact.qualification"
                            type="text"
                        />
                      </el-form-item>
                      <el-form-item label-position="top" :label=" getMessageByCode('icaew') ?? 'ICAEW URL'">
                        <el-input v-model="clientContact.icaewUrl" :disabled="edit"></el-input>
                      </el-form-item>
                      <el-form-item label-position="top">
                        <template #label>
                          <div class="d-flex justify-content-between align-items-center">
                            <span class="text-capitalize">{{
                                getMessageByCode('is_audit') ?? 'Is Audit'
                              }}</span>
                            <el-switch
                                :disabled="edit"
                                inline-prompt
                                active-text="Yes"
                                inactive-text="No" v-model="clientContact.isAudit"></el-switch>

                          </div>
                        </template>
                      </el-form-item>
                      <el-form-item label-position="top">
                        <template #label>
                          <div class="d-flex justify-content-between align-items-center">
                            <span class="text-capitalize">{{
                                getMessageByCode('is_firm') ?? 'Is Firm'
                              }}</span>
                            <el-switch
                                :disabled="edit"
                                inline-prompt
                                active-text="Yes"
                                inactive-text="No" v-model="clientContact.isFirm"></el-switch>

                          </div>
                        </template>
                      </el-form-item>
                      <el-form-item label-position="top">
                        <template #label>
                          <div class="d-flex justify-content-between align-items-center">
                            <span class="text-capitalize">{{
                                getMessageByCode('is_lics') ?? 'Is Licensed'
                              }}</span>
                            <el-switch
                                :disabled="edit"
                                inline-prompt
                                active-text="Yes"
                                inactive-text="No" v-model="clientContact.isActive"></el-switch>

                          </div>
                        </template>
                      </el-form-item>
                      <el-form-item label-position="top">
                        <template #label>
                          <div class="d-flex justify-content-between align-items-center">
                            <span class="text-capitalize">{{
                                getMessageByCode('is_investment_business') ?? 'Is Investment Business'
                              }}</span>
                            <el-switch
                                :disabled="edit"
                                inline-prompt
                                active-text="Yes"
                                inactive-text="No" v-model="clientContact.isActive"></el-switch>

                          </div>
                        </template>
                      </el-form-item>
                      <el-form-item label-position="top">
                        <template #label>
                          <div class="d-flex justify-content-between align-items-center">
                            <span class="text-capitalize">{{
                                getMessageByCode('is_offer_bas') ?? 'Is Offer BAS'
                              }}</span>
                            <el-switch
                                :disabled="edit"
                                inline-prompt
                                active-text="Yes"
                                inactive-text="No" v-model="clientContact.isOfferBas"></el-switch>

                          </div>
                        </template>
                      </el-form-item>

                    </el-form>
                  </div>
                </div>
              </div>
            </div>
          </el-form>

        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog v-model="addTypeDialog" :title="getMessageByCode('addType') ?? 'Add Type'" width="400">
      <template #default>
        <el-form label-position="top" require-asterisk-position="right">
          <el-form-item :label="getMessageByCode('title') ?? 'title'">
            <el-input v-model="type.title"></el-input>
          </el-form-item>
          <el-form-item :label="getMessageByCode('sortOrder') ?? 'Sort Order'">
            <el-input type="number" v-model="type.sortOrder"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addTypeDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button type="primary" v-if="type.id < 1" @click="addTypeDialog = false;addType()">
            {{ getMessageByCode('add') ?? 'Add' }}
          </el-button>
          <el-button v-else type="primary" @click="addTypeDialog = false;updateType()">
            {{ getMessageByCode('update') ?? 'Update' }}
          </el-button>
        </div>
      </template>
    </el-dialog>


    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>

<script>
import {ShowMessage} from "@/Utility/Utility";
import store from "@/store";
import {checkPermission} from "@/Utility/CheckPermission";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import marketService from "@/Services/ComponentsServices/marketService";
import {getLanguage} from "@/Utility/getLanguage";
import moment from "moment/moment";
import clientContactService from "@/Services/ComponentsServices/clientContactService";
import {NInputGroup} from "naive-ui";

export default {
  name: "ClientContact",
  components: {NInputGroup, BreadCrumb},
  data() {
    return {
      activeTab: 1,
      mainBtnText: "Add Client",
      updateUserDialog: false,
      addTypeDialog: false,
      clientId: sessionStorage.getItem("clientId"),
      User: {},
      addDisabled: false,
      clientEdit: false,
      edit: true,
      passwordExists: false,
      clientCreate: false,
      loading: false,
      markets: [],
      formRules: {
        nameRule: [
          {required: true, message: 'Please enter your name', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        emailRule: [
          {required: true, message: 'Please enter the email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ], userEmail: [
          {required: true, message: 'Please enter the user email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid user email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ],
      },
      translations: [],
      types: [],
      type: {
        "id": 0,
        "title": "",
        "sortOrder": 0
      },
      clientContact: {
        "id": 0,
        "companyName": "",
        "firstName": "",
        "lastName": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "address4": "",
        "town": "",
        "region": "",
        "postCode": "",
        "website": "",
        "email1": "",
        "email2": "",
        "telephone": "",
        "mobile": "",
        "typeId": 0,
        "isAudit": false,
        "isFirm": false,
        "isActive": false,
        "isLicensedPractitioner": false,
        "isInvestmentBusiness": false,
        "qualification": "",
        "isOfferBas": false,
        "icaewUrl": "",
        "clientId": 0,
        "clientGuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "addedDate": "2024-10-29T10:02:05.169Z",
        "addedBy": "",
        "modifiedDate": "2024-10-29T10:02:05.169Z",
        "modifiedBy": "",
        "isDeleted": false,
        "deletedDate": "2024-10-29T10:02:05.169Z",
        "deletedBy": "",
        "marketId": 0,
        "isAppAllowed": false
      },

      filter: {
        clientId: 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
        guid: ""
      },
      dateFormat: "DD/MM/YYYY",
    }
  },
  methods: {
    getFormattedDate(date) {
      if (date === null || date === undefined) {
        return "";
      }
      return moment.utc(date).local().format(this.dateFormat);
    },
    checkClient() {
      if (this.clientContact.id < 1) {
        this.$router.push('clientcontacts');
        return;
      }
      this.edit = !this.edit
    },
    validateEmail(rule, value, callback) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        callback();
      } else {
        callback(new Error('Please enter a valid email address'));
      }
    },
    async addClientContact() {
      this.loading = true;
      if (this.clientContact.name === "") {
        ShowMessage("error", "Please enter the required fields");
        return;
      }
      try {

        // eslint-disable-next-line no-unused-vars
        let response = await clientContactService.addContact(this.clientContact);

        if (response.status === 200) {
          ShowMessage("success", "Client Contact added successfully");
          this.$router.push('/clientcontacts')
        }
        this.loading = false;
      } catch (e) {
        ShowMessage("error", e.response.data.message);
        this.loading = false;
      }
      this.loading = false;
    },
    async updateClientContact() {
      this.loading = true;

      try {
        // eslint-disable-next-line no-unused-vars
        let response = await clientContactService.updateContact(this.clientContact);
        if (response.status === 200) {
          ShowMessage("success", "Client Contact updated successfully");
          this.$router.push('/clientcontacts')
        }
        this.loading = false;
      } catch (e) {
        console.log(e)
        ShowMessage("error", "Client Contact update failed");
        this.loading = false;
      }
    },
    async getClientContact() {
      try {
        this.loading = true;
        let response = await clientContactService.getContactById(this.$store.state.pageId);
        this.clientContact = response.data ?? {};
        this.loading = false;
      } catch {
        ShowMessage("error", "Something went wrong!");
        this.loading = false;
      }
    },

    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getClientUsers();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getClientUsers();
    },
    async getMarkets() {
      try {
        let response = await marketService.getAll();
        this.markets = response.data.items ?? [];
        if (this.markets.length > 0) {
          this.clientContact.marketId = this.markets[0].id;
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getTypes() {
      try {
        let response = await clientContactService.getAllTypes();
        this.types = response.data ?? [];

        if (this.types.length > 0) {
          this.clientContact.typeId = this.types[0].id;
        }
      } catch (e) {
        console.log(e)
      }
    },

    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async addType() {
      this.loading = true;
      try {
        let response = await clientContactService.addType(this.type);
        if (response?.status === 200) {
          ShowMessage('success', "Type added");
          await this.getTypes();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.msg ?? "Something went wrong")
      }
      this.loading = false;
    },
    async updateType() {
      this.loading = true;
      try {
        let response = await clientContactService.updateType(this.type);
        if (response?.status === 200) {
          ShowMessage('success', "Type updated");
          await this.getTypes();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.msg ?? "Something went wrong")
      }
      this.loading = false;
    },
    async deleteType(typeId) {
      this.loading = true;
      try {
        let response = await clientContactService.deleteType(typeId);
        if (response?.status === 200) {
          ShowMessage('success', "Type deleted");
          await this.getTypes();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.msg ?? "Something went wrong")
      }
      this.loading = false;
    },
    resetType() {
      this.type = {
        id: 0,
        title: "",
        sortOrder: 0
      }
    }
  },
  created() {
    this.translations = getLanguage();
    this.clientEdit = checkPermission("MD_CLIENT_EDIT");
    this.clientCreate = checkPermission("MD_CLIENT_CREATE");
    this.getMarkets();
    this.getTypes();
    if (store.state.pageId > 0) {
      this.mainBtnText = this.getMessageByCode('update_client') ?? 'Update Client';
      this.getClientContact();
    } else {
      this.edit = false;
      this.mainBtnText = this.getMessageByCode('add_client') ?? 'Add Client';
    }
  }
}
</script>

<style scoped>

</style>