<template>
  <div class="container-fluid header-shadow custom-header">
    <el-row align="middle" class="w-100" justify="space-between">
      <el-col :offset="0" :span="12" class="d-flex">
        <div class="btn-div d-md-none" @click="showSidebar = !showSidebar, $store.state.sidebarCollapsed = false">
          <i class="mgc_menu_line"></i>
        </div>
        <div class="d-none d-md-block" @click="$store.state.sidebarCollapsed = !$store.state.sidebarCollapsed">
          <div class="btn-div me-3">
            <i class="mgc_menu_line"></i>
          </div>
        </div>
        <el-space class="d-none d-md-flex">
          <router-link   v-if="market.toLowerCase() === ''"  to="dashboard">
            <el-button bg text>{{getMessageByCode('dashboard') ?? 'Dashboard'}}</el-button>
          </router-link>
          <router-link v-if="market.toLowerCase() === 'housing management'" to="home">
            <el-button bg text>{{getMessageByCode('dashboard')?? 'Dashboard'}}</el-button>
          </router-link>
          <router-link v-if="market.toLowerCase() === 'property management'" to="pms">
            <el-button bg text>{{ getMessageByCode('dashboard') ?? 'Dashboard' }}</el-button>
          </router-link>
          <router-link v-if="market.toLowerCase() === 'housing management' && propertyView" to="properties">
            <el-button bg text>{{getMessageByCode('properties') ?? 'Properties'}}</el-button>
          </router-link>
          <router-link v-if="market.toLowerCase() === 'property management' && pmsPropertyView" to="pmsproperties">
            <el-button bg text>{{ getMessageByCode('pmsproperties') ?? 'Properties' }}</el-button>
          </router-link>
        </el-space>
      </el-col>
      <div class="d-flex align-items-center">

        <el-dropdown>
          <img
              v-if="User.image !== '' && User.image !== null"
              alt="Profile"
              class="rounded-2"
              height="35"
              :src="User.image"
              width="35"
          />
          <el-avatar v-else  shape="square">
            <el-icon>
              <i class="bi bi-person-fill fs-2"></i>
            </el-icon>
          </el-avatar>

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item disabled>
                <i class="bi bi-person me-2"></i>
                <span>{{ User.displayName }}</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link class="pr-link" to="profile">
                  <div class="row ">
                    <div class="d-flex justify-content-between align-items-center">
                      <i class="bi bi-person-gear me-2"></i>
                      <div class="d-flex flex-column">
                        <span class="mfw-bold">{{getMessageByCode('my_prof')??'My Profile'}}</span>
                        <span class="text-caption text-capitalize">{{getMessageByCode('acc_sett')??'Account settings and more'}}</span>
                      </div>
                      <i class="mgc_right_line"></i>
                    </div>
                  </div>
                </router-link>
              </el-dropdown-item>
              <el-divider border-style="dashed" class="my-1"></el-divider>
              <div class="py-3 px-3">
                <el-button class="btn-db-secondary" @click="logout()">{{getMessageByCode('logout')??'Logout'}}</el-button>
              </div>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

      </div>
    </el-row>
    <div
        :class="{
        'sm-sidebar': true,
        'sb-w-100': showSidebar,
        'sb-w-0': !showSidebar,
      }"
    >
      <Sidebar @close-sidebar="showSidebar = false"/>
    </div>

  </div>
</template>
<script>
import Sidebar from "@/components/Navigation/Sidebar";
import userService from "@/Services/ComponentsServices/userService";
import { mapState } from "vuex";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";
export default {
  name: "HeaderComp",
  components: {Sidebar},
  data() {
    return {
      showSidebar: false,
      propertyView: false,
      pmsPropertyView: false,
      clientId: sessionStorage.getItem("clientId"),
      translations:[],
      market: sessionStorage.getItem("market") ?? "",
      User: {
        "id": sessionStorage.getItem("mUserId"),
        "clientId": 1,
        "userName": sessionStorage.getItem("clientId"),
        "email": "",
        "image":"",
        "displayName": "",
        "isActive": false,
        "mobile": null,
        "isEmailNotification": false,
        "permissionList": []
      },
    };
  },
  computed: {
    ...mapState(["refreshProfile"]), // Map the state to a local computed property
  },
  watch: {
    refreshProfile(newVal) {
      if (newVal) {
        this.getUser();
      }
    },
  },
  methods: {
    async getUser() {
      try {
        let response = await userService.getbyId(this.User.id);
        this.User = response.data;
        this.$store.state.refreshProfile = false;
      } catch (e) {
        console.log(e)
      }
    },
    logout() {
      sessionStorage.clear();
      localStorage.clear();
      this.$router.push('/');
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.propertyView = checkPermission("MD_PROP_VIEW");
    this.pmsPropertyView = checkPermission("MD_PMSPROP_VIEW");
    this.getUser();
    this.translations = getLanguage();

  }
};
</script>
<style scoped>
.sm-sidebar {
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #f9f9f9;
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  z-index: 999;
  transition: width 0.4s ease-in-out;
}

.sb-w-100 {
  width: 200px !important;
}

.sb-w-0 {
  width: 0 !important;
  overflow: hidden;
}

a {
  color: #606266 !important;
  text-decoration: none !important;
}
</style>
