import config from "@/Configuration/Config";
import axios from 'axios';

export default {
    getRentsUrl: config.apiBaseUrl + "pmsrent/getrents?clientId=",
    getRentUrl: config.apiBaseUrl + "pmsrent/getrentbyid?rentId=",
    addRentUrl: config.apiBaseUrl + "pmsrent/add",
    updateRentUrl: config.apiBaseUrl + "pmsrent/update",
    deleteRentUrl: config.apiBaseUrl + "pmsrent/delete?rentId=",


    getRents(filter) {

        return axios.get(this.getRentsUrl+filter.clientId+"&month="+filter.month+"&propertyId=" + filter.propertyId + "&tenantId=" + filter.tenantId  + "&status=" + filter.status + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getRent(id) {
        return axios.get(this.getRentUrl + id);
    },
    addRent(rent) {
        return axios.post(this.addRentUrl, rent);
    },
    updateRent(rent) {
        return axios.put(this.updateRentUrl, rent);
    },

    deleteRent(id) {
        return axios.delete(this.deleteRentUrl + id);
    }


}