import Config from "@/Configuration/Config";
import axios from "axios"

export default {
    addUrl: Config.apiBaseUrl + "customernote/add",
    updateUrl: Config.apiBaseUrl + "customernote/update",
    getNotesUrl: Config.apiBaseUrl + "customernotes/getcustomernotes?customerId=",
    getUrl: Config.apiBaseUrl + "customernotes/getcustomernote?customerId=",
    deleteUrl: Config.apiBaseUrl + "customernotes/delete?customerId=",


    addNote(note) {
        return axios.post(this.addUrl, note)
    },
    updateNote(note) {
        return axios.put(this.updateUrl, note)
    },
    getNotes(filter) {
        return axios.get(this.getNotesUrl + filter.customerId + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getNote(noteId) {
        return axios.get(this.getUrl + noteId)
    },
    deleteNote(noteId) {
        return axios.delete(this.deleteUrl + noteId);
    }
}