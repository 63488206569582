export function checkPermission(permission) {

    let permissions = JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
        return permissions.some(
            (perm) => perm.permission === permission
        );
    } else {
        return false; // No permissions available
    }
}