import config from "@/Configuration/Config";
import axios from 'axios'

export default {
    getImagesUrl: config.apiBaseUrl + "logincore/getimages?status=",
    getLoginImageUrl: config.apiBaseUrl + "logincore/getloginimage?category=",
    getImageByIdUrl: config.apiBaseUrl + "logincore/getimagebyid?imageId=",
    addImageUrl: config.apiBaseUrl + "logincore/addimage",
    updateImageUrl: config.apiBaseUrl + "logincore/updateimage",
    deleteImageUrl: config.apiBaseUrl + "logincore/deleteimage?imageId=",

    // Login Quote Url
    getQuotesUrl: config.apiBaseUrl + "logincore/getquotes?status=",
    getLoginQuoteUrl: config.apiBaseUrl + "logincore/getloginquote",
    getQuoteByIdUrl: config.apiBaseUrl + "logincore/getimagebyid?quoteId=",
    addQuoteUrl: config.apiBaseUrl + "logincore/addquote",
    updateQuoteUrl: config.apiBaseUrl + "logincore/updatequote",
    deleteQuoteUrl: config.apiBaseUrl + "logincore/deletequote?quoteId=",

    // Login Image Methods
    getImages(filter) {
        return axios.get(this.getImagesUrl +filter.status +"&category="+filter.category+ "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getImageById(id) {
        return axios.get(this.getImageByIdUrl + id);
    },
    getLoginImage(category) {
        return axios.get(this.getLoginImageUrl + category);
    },
    addImage(image) {
        return axios.post(this.addImageUrl, image);
    },
    updateImage(image) {
        return axios.put(this.updateImageUrl, image);
    },
    deleteImage(id) {
        return axios.delete(this.deleteImageUrl + id);
    },
    // Login Quotes Methods
    getQuotes(filter) {
        return axios.get(this.getQuotesUrl + filter.status + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getQuoteById(id) {
        return axios.get(this.getQuotesUrl + id);
    },
    getLoginQuote() {
        return axios.get(this.getLoginQuoteUrl);
    },
    addQuote(quote) {
        return axios.post(this.addQuoteUrl, quote);
    },
    updateQuote(quote) {
        return axios.put(this.updateQuoteUrl, quote);
    },
    deleteQuote(id) {
        return axios.delete(this.deleteQuoteUrl + id);
    }
}