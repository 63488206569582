<template>
  <div class="container-fluid">
    <ToolBar :show-select="false" :show-add-btn="false" :show-bread="false"></ToolBar>
    <div class="row justify-content-end">
      <div class="col-12 col-md-3 d-flex align-items-end mb-1">
        <div v-if="clientId == null || clientId < 1"  class="d-flex flex-column flex-grow-1 ms-2">
          <span class="text-small">{{getMessageByCode('scope')?? 'Scope' }}</span>
          <el-select v-model="filter.typeId" filterable @change="getTenants()">
            <el-option v-for="opt in Scopes" :key="opt.optId" :label="opt.optTitle"
                       :value="opt.optId"></el-option>
          </el-select>
        </div>
        <el-dropdown>
          <el-button type="primary" class="ms-2">
            {{ getMessageByCode('export')?? 'Export' }}<el-icon class="el-icon--right"><i class="bi bi-chevron-down"></i></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu class="w-100">
              <el-dropdown-item @click="ExportToExcel()">
                <i class="bi bi-file-excel me-1"></i>
                <span>Excel</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <el-table  :data="Tenants"
                :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                header-cell-class-name="tbl-header"
                stripe>
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              small
          >
          </el-pagination>
        </template>
        <el-table-column sortable :label="getMessageByCode('name') ??'Name'" prop="name" min-width="200"></el-table-column>
        <el-table-column sortable :label="getMessageByCode('address')?? 'Address'" prop="address" min-width="180"></el-table-column>
        <el-table-column sortable :label="getMessageByCode('start_date')?? 'Start Date'" min-width="120">
          <template #default="scope">
            <span>{{ scope.row.startDate?.toString("dd/mm/yyyy") }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="getMessageByCode('end_date')?? 'End Date'"  min-width="120">
          <template #default="scope">
            <span>{{ scope.row.endDate?.toString("dd/mm/yyyy") }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable :label="getMessageByCode('crn')?? 'CRN'" prop="crn" min-width="120"></el-table-column>
        <el-table-column sortable :label="getMessageByCode('authority')?? 'Authority'" prop="localAuthority" min-width="180"></el-table-column>
        <el-table-column sortable :label="getMessageByCode('gender')?? 'Gender'" prop="gender" min-width="100"></el-table-column>
        <el-table-column sortable :label="getMessageByCode('eth')?? 'Ethnicity'" prop="ethnicty" min-width="120"></el-table-column>
        <el-table-column sortable :label="getMessageByCode('ref_method')?? 'Reference Method'" prop="referral" min-width="180"></el-table-column>
        <el-table-column sortable :label="getMessageByCode('sprt_plan')?? 'Support Plan'" prop="supportPlan" min-width="140"></el-table-column>
        <el-table-column :label="getMessageByCode('action')?? 'Action'" align="center" fixed="right" width="80">
          <template #default="scope">
            <el-space alignment="center" spacer="|">
              <i
                  class="bi bi-info-circle-fill action-btn"
                  @click="viewTenant(scope.row.id)"
              ></i>

            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import reportServices from "@/Services/ComponentsServices/reportServices";
import {ShowMessage} from "@/Utility/Utility";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "TenantsReport",
  components: {ToolBar},
  data() {
    return {
      loading: false,
      Tenants: [],
      translations: [],
      clientId: sessionStorage.getItem("clientId"),
      Scopes: [
        {optTitle: "All Active Tenants", optId: 1},
        {optTitle: "Allocated Tenants Only", optId: 2},
        {optTitle: "Unallocated Tenants Only", optId: 3},
      ],
      ExpiryOptions: [
        {optTitle: "All", optId: 0},
        {optTitle: "90 Days", optId: 90},
        {optTitle: "28 Days", optId: 28},
        {optTitle: "14 Days", optId: 14},
        {optTitle: "07 Days", optId: 7},
        {optTitle: "Expired", optId: -1},
      ],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        days: 0,
        typeId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {
    viewTenant(id) {
      this.$store.state.pageId = id;
      this.$router.push('/tenant');
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getTenants();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getTenants();
    },
    async getTenants() {
      try {
        this.loading = true;
        let response = await reportServices.getTenants(this.filter);
        this.Tenants = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage =response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async ExportToExcel(){
      try {
        this.loading  = true;
        // eslint-disable-next-line no-unused-vars
        let response = await reportServices.downloadTenants(this.filter);
        if (response.data == null){
          ShowMessage("error", "Something went wrong!");
          return;
        }
        let sampleArr = this.base64ToArrayBuffer(response.data);
        this.saveByteArray("Tenants Report.xlsx", sampleArr);
        this.loading  = false;
        ShowMessage("success", "Report Downloaded");
      }
      catch {
        this.loading  = false;
        ShowMessage("error","Something went wrong!");
      }
    },
    base64ToArrayBuffer(base64) {
      let binaryString = window.atob(base64);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/xlsx"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    } ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.getTenants();

  }
}
</script>

<style scoped>

</style>