import axios from "axios"
import Config from "@/Configuration/Config";


export default {
    allurl: Config.apiBaseUrl + "document/getdocuments?clientId=",
    getallurl: Config.apiBaseUrl + "document/getdocuments?limit=1500",
    geturl: Config.apiBaseUrl + "room/getdocumentbyid?id=",
    getdoctypesurl: Config.apiBaseUrl + "document/getdocumenttypes?clientId",
    addpropertydocurl: Config.apiBaseUrl + "document/addpropertydoc",
    updateurl: Config.apiBaseUrl + "document/update",
    deleteurl: Config.apiBaseUrl + "document/delete?documentId=",
    deletePropertyDocUrl: Config.apiBaseUrl + "document/deletepropertydoc?documentId=",
    getpropertydocsurl: Config.apiBaseUrl + "document/getpropertydocuments?proId=",
    getDocumentsCategoriesUrl: Config.apiBaseUrl + "listoption/getoptionlistbyid?clientId=",
    addUrl :Config.apiBaseUrl +"document/add",



    downloadurl: Config.apiBaseUrl + "document/download?docId=",
    //Compliance Docs
    addComplianceDocUrl: Config.apiBaseUrl + "document/addcompliancepropertydoc",


    all(filter) {
        return axios.get(this.allurl + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll(proId) {
        return axios.get(this.getallurl + "&proId=" + proId);
    },
    getpropertydocs(propertyId) {
        return axios.get(this.getpropertydocsurl + propertyId + "&limit=1000")
    },
    getbyId(id) {
        return axios.get(this.geturl + id);
    },
    addPropertyDoc(document) {
        return axios.post(this.addpropertydocurl, document);
    },
    update(room) {
        return axios.put(this.updateurl, room);
    },
    getDocumentsCategories(clientId) {
        return axios.get(this.getDocumentsCategoriesUrl+clientId +"&headerId=11");
    },
    getTypes(clientId) {
        return axios.get(this.getdoctypesurl+clientId);
    },
    delete(id) {
        return axios.delete(this.deleteurl + id);
    },
    download(id){
        return axios.get(this.downloadurl+id);
    },
    //Compliance Docs
    addComplianceDoc(document) {
        return axios.post(this.addComplianceDocUrl, document);
    },
    addDocument(document){
        return axios.post(this.addUrl, document)
    },
    deletePropertyDoc(docId){
        return axios.delete(this.deletePropertyDocUrl+docId)
    }
}