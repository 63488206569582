import config from "@/Configuration/Config";
import axios from 'axios';

export default {
    getReceiptsUrl: config.apiBaseUrl + "pmsreceipt/getreceipts?propertyId=",
    getReceiptUrl: config.apiBaseUrl + "pmsreceipt/getreceiptbyid?receiptId=",
    addReceiptUrl: config.apiBaseUrl + "pmsreceipt/add",
    updateReceiptUrl: config.apiBaseUrl + "pmsreceipt/update",
    deleteReceiptUrl: config.apiBaseUrl + "pmsreceipt/delete?receiptId=",


    getReceipts(filter) {
        return axios.get(this.getReceiptsUrl + filter.propertyId + "&tenantId=" + filter.tenantId + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getReceipt(id) {
        return axios.get(this.getReceiptUrl + id);
    },
    addReceipt(receipt) {
        return axios.post(this.addReceiptUrl, receipt);
    },
    updateReceipt(receipt) {
        return axios.put(this.updateReceiptUrl, receipt);
    },

    deleteReceipt(id) {
        return axios.delete(this.deleteReceiptUrl + id);
    }


}