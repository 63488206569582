<template>
  <div class="container-fluid">
    <ToolBar :show-bread="false" clear-id="propertyId"
             :showSelect="true"
             :ShowAddBtn="false" routeName="addpmsproperty"
             :btnText="getMessageByCode('add_prop')?? 'Add Property'">
      <template v-slot:select>
        <el-select v-if="clientId == null || Number(clientId) === 0" class="w-100" filterable
                   @change="getEmails();getContacts();this.getProperties(); "
                   v-model="filter.clientId">
          <el-option v-for="item in clients"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id">
            <template #default>
              <div class="row">
                <span class="col-7">{{ item.name }}</span>
                <span class="text-small col-5">{{ item.market }}</span>
              </div>
            </template>
          </el-option>
        </el-select>
        <el-button type="primary" class="ms-2" @click="addEmailDialog = true;resetEmail()">{{
            getMessageByCode('addEmail') ?? 'Add Email'
          }}
        </el-button>
      </template>
    </ToolBar>
    <div>

      <div class="d-flex justify-content-end mb-1">
        <div class="col-12 col-md-4 col-lg-4 mb-1  p-0 m-0 d-flex justify-content-end">
          <el-input v-model="filter.search" class="align-self-end ms-md-3" clearable
                    :placeholder="getMessageByCode('search') ?? 'Search here...'"
                    @clear="getEmails"
                    @keyup="getEmails">
            <template #append>
              <el-button @click="getEmails">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>

        </div>
      </div>
      <el-table :data="emails"
                :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                header-cell-class-name="tbl-header"
                stripe>
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              size="small"
          >
          </el-pagination>
        </template>
        <el-table-column sortable width="200" :label="getMessageByCode('sentDate')??'Sent Date'"
                         prop="sentDate">
          <template #default="scope">
            <span>{{ getEmailFormattedDate(scope.row.sentDate) }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable width="200" :label="getMessageByCode('to')??'To'"
                         prop="to">
          <template #default="scope">
            <span>{{ scope.row.toName }}</span>
            <n-popover trigger="hover">
              <template #trigger>
                <i class="bi bi-envelope ms-2 action-btn text-primary"></i>
              </template>
              <span>{{ scope.row.to }}</span>
            </n-popover>
          </template>
        </el-table-column>
        <el-table-column sortable  :label="getMessageByCode('subject')??'Subject'"
                         prop="subject"></el-table-column>
        <el-table-column sortable :label="getMessageByCode('contactType')??'Contact Type'"
                         prop="contactType"></el-table-column>
        <el-table-column sortable width="200" :label="getMessageByCode('property')??'Property'"
                         prop="property">
          <template #default="scope">
            <span>{{ scope.row.property }}</span>
            <i v-if="scope.row.propertyId > 0 " class="ms-2 bi bi-link-45deg text-primary action-btn"
               @click="goToProperty(scope.row.propertyId)"></i>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="120">
          <template #default="scope">
            <el-space alignment="center" spacer="|">
              <el-tooltip :content="getMessageByCode('attachments') ?? 'Attachments'">
                <i @click="editEmail(scope.row,'attach')" class="action-btn bi bi-paperclip ms-2"
                   style="font-size: 13px; rotate: 45deg"></i>
              </el-tooltip>
              <i
                  class="mgc_eye_2_line text-primary action-btn"
                  @click="editEmail(scope.row,'edit')"
              ></i>
              <i @click="editEmail(scope.row,'delete')"
                 class="mgc_delete_2_line text-danger action-btn"
              ></i>
            </el-space>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    Dialogs-->
    <el-dialog v-model="addEmailDialog" align-center
               :title="email.id > 0 ?  getMessageByCode('viewEmail') ?? 'View Email' :  getMessageByCode('addEmail') ?? 'Add Email'"
               style="width: auto; max-width: 670px">
      <el-form label-position="top" require-asterisk-position="right" class="row">

        <el-form-item required class="col-12 col-md-6">
          <div class="d-flex align-items-center w-100">
            <el-select class="" filterable :disabled="email.id > 0" v-model="email.contactId"
                       :placeholder="getMessageByCode('to') ?? 'To'">
              <el-option v-for="item in contacts"
                         :key="item.id"
                         :label="getTenantName(item)"
                         :value="item.id">
                <template #default>
                  <div class="row">
                    <span class="col-7">{{ getTenantName(item) }}</span>
                    <span class="text-small col-5">{{ item.category }}</span>
                  </div>
                </template>
              </el-option>
            </el-select>
            <el-tooltip
                v-if="email.id < 1 && (clientMarket === 'Housing Management' || clientMarket === 'Property Management')"
                :content="getMessageByCode('addProperty') ?? 'Add Property'">
              <el-button :disabled="email.id > 0" @click="propertyDisplay = !propertyDisplay; email.propertyId  = ''"
                         :type="propertyDisplay ? 'primary' : 'default'"
                         class="px-2 ms-1">
                <i class="bi bi-house-add fs-3 action-btn"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip v-if="email.id < 1" :content="getMessageByCode('addCC') ?? 'Add CC'">
              <el-button :disabled="email.id > 0" @click="ccDisplay = !ccDisplay; email.copyTo = ''"
                         :type="ccDisplay ? 'primary' : 'default'" class="px-2 ms-1">
                <i class="bi bi-cc-circle fs-3 action-btn"></i>
              </el-button>
            </el-tooltip>
          </div>
        </el-form-item>
        <transition name="el-zoom-in-left">
          <el-form-item v-if="propertyDisplay || (email.id > 1 && email.propertyId > 0)" class="col-12 col-md-6">
            <el-select :disabled="email.id > 0" placeholder="Select Property" v-model="email.propertyId" clearable
                       @clear="email.propertyId = ''">
              <el-option v-for="item in properties"
                         :key="item.id"
                         :label="item.propertyName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </transition>
        <el-collapse-transition>
          <el-form-item v-if="ccDisplay || (email.id > 1 && email.copyTo)" class="mt-1">
            <el-input :placeholder="getMessageByCode('cc') ?? 'CC'" :disabled="email.id > 0"
                      v-model="email.copyTo"></el-input>
          </el-form-item>
        </el-collapse-transition>
        <el-form-item required class="mt-1">
          <el-input :placeholder="getMessageByCode('subject') ?? 'Subject'" :disabled="email.id > 0"
                    v-model="email.subject" :maxlength="250" show-word-limit></el-input>
        </el-form-item>
        <el-form-item :disabled="email.id > 0" class="mt-1">

          <div v-if="email.id < 1" class="w-100">
            <ckeditor

                :disabled="email.id > 1"
                :editor="editor"
                class="w-100"
                v-model="email.body"
                :config="editorConfig"
                style="width: 100% !important; min-height: 150px !important;">
            </ckeditor>
          </div>

          <div v-else style="border: 1px solid #e2e3e5; padding: 5px; border-radius: 5px; width: 100%"
               v-html="email.body"></div>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <el-upload v-if="email.id < 1" id="upload-photo" multiple accept="image/*" :key="fileInputKey"
                           :auto-upload="false"
                           @input="onFileInput" :show-file-list="false"
                           class="mb-0">
                  <template #trigger>
                    <el-button type="primary" size="small" class="mt-1">
                      {{ getMessageByCode('addAttachments') ?? 'Add Attachments' }} <i class="bi bi-upload ms-2"></i>
                    </el-button>
                  </template>
                </el-upload>
                <span
                    v-if="email.id > 1 && email.attachments.length > 0">{{ getMessageByCode('attachments') ?? 'Attachments' }}</span>
              </div>
              <div class="d-flex align-items-center">
                <span class="fs-normal me-2">{{ getMessageByCode('addSignature') ?? 'Add Signature' }}?</span>
                <el-checkbox v-model="email.addSignature"></el-checkbox>
              </div>
            </div>
          </template>
          <template #default>
            <div class="container-fluid">
              <div class="row">
                <div
                    class="col-md-6 mb-0 "
                    v-for="(attach, index) in email.attachments"
                    :key="index"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <span class="fs-normal">{{ index + 1 }})</span>
                      <el-tooltip :content="attach.fileName">
                        <span class="ms-2 fs-normal">{{ truncateString(attach.fileName) }}</span>
                      </el-tooltip>
                      <span class="ms-2 fs-normal">({{ SizeCalc.FormattedSize(attach.fileSize) }})</span>

                    </div>
                    <el-tooltip v-if="email.id < 1" :content="getMessageByCode('remove') ?? 'Remove'">
                      <n-button @click="removeAttachment(attach)" tertiary size="tiny" class="ms-2" circle>
                        <i class="bi bi-x"></i>
                      </n-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <div v-if="email.id < 1">
            <el-button text bg class="ms-2" @click="addEmailDialog = false">Cancel</el-button>
            <el-button type="primary" class="ms-2"
                       :disabled="email.contactId < 1 || !email.subject || !email.body"
                       @click="addEmail()">
              {{
                getMessageByCode('sendEmail') ?? 'Send Email'
              }}
            </el-button>
          </div>
          <el-button v-else type="primary" class="ms-2"
                     @click="addEmailDialog = false">
            {{
              getMessageByCode('close') ?? 'Close'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteEmailDialog"
               :title="getMessageByCode('deleteEmail') ?? 'Delete Email'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold ">selected email?</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteEmailDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button type="danger" class="ms-2" @click="deleteEmailDialog = false; deleteEmail()">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="viewAttachments"
               :title="getMessageByCode('attachments') ?? 'Attachments'"
               style="width: auto; max-width: 500px">
      <n-list v-if="email.attachments.length > 0" bordered>
        <n-list-item v-for="(attachment,index) in email.attachments" :key="attachment.id" class="py-1">
          <div class="d-flex justify-content-between align-items-center fs-normal">
            <div>
              <span>{{ index + 1 }})</span>
              <span class="ms-1">{{ attachment.fileName }}</span>
              <span class="text-sm ms-2">({{ SizeCalc.FormattedSize(attachment.fileSize) }})</span>
            </div>
            <div>
              <el-tooltip :content="getMessageByCode('download') ?? 'Download'">
                <i class="bi bi-download action-btn text-primary" @click="downloadAttachment(attachment)"></i>
              </el-tooltip>
            </div>
          </div>
        </n-list-item>
      </n-list>
      <span v-else>
        {{ getMessageByCode('noAttachments') ?? 'No attachments available for the email' }}
      </span>
    </el-dialog>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getLanguage} from "@/Utility/getLanguage";
import contactService from "@/Services/ComponentsServices/contactService";
import emailService from "@/Services/ComponentsServices/emailService";
import {ShowMessage} from "@/Utility/Utility";
import ClientService from "@/Services/ComponentsServices/clientService";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import configService from "@/Services/ComponentsServices/configService";
import pmsPropertiesService from "@/Services/ComponentsServices/pmsPropertiesService";
import {NPopover} from "naive-ui";
import moment from "moment";
import SizeCalc from "../Services/SizeCalc";
import {NList, NListItem} from "naive-ui";
import {NButton} from "naive-ui";
import propertyService from "@/Services/ComponentsServices/PropertyService";

export default {
  name: "PmsEmails",
  computed: {
    SizeCalc() {
      return SizeCalc
    }
  },
  components: {NButton, NListItem, NList, NPopover, ToolBar, ckeditor: CKEditor.component},
  data() {
    return {
      clientId: sessionStorage.getItem("clientId"),
      loading: false,
      addEmailDialog: false,
      viewAttachments: false,
      deleteEmailDialog: false,
      translations: [],
      clients: [],
      emails: [],
      contacts: [],
      properties: [],
      dateFormat: "DD/MM/YYYY",
      propertyDisplay: false,
      ccDisplay: false,
      fileInputKey: new Date(),
      maxFileUploads: 1,
      fileMaxSize: 5,
      fromEmail: "",
      clientMarket: sessionStorage.getItem("market") ?? "",
      email: {
        "id": 0,
        "userId": sessionStorage.getItem("userId"),
        "emailFrom": "",
        "emailTo": "",
        "copyTo": "",
        "subject": "",
        "body": "Email body goes here",
        "propertyId": 0,
        "clientId": 0,
        "sentDate": new Date(),
        "status": 0,
        "contactId": "",
        "providerId": 0,
        "addSignature": false,
        attachments: [],
      },
      attachment: {
        "id": 0,
        "emailId": 0,
        "fileName": "",
        "fileExtension": "",
        "fileSize": 0,
        "mimeType": "",
        "fileData": "",
        "isDeleted": false,
        "addedDate": new Date(),
        "addedBy": "",
        "modifiedDate": null,
        "modifiedBy": "",
        "deletedDate": null,
        "deletedBy": ""
      },
      editor: ClassicEditor,
      editorConfig: {
        height: 500,
        toolbar: [
          "undo", "redo", "heading", "bold", "italic", "blockQuote", "link", "numberedList", "bulletedList", "insertTable"
        ],
      },
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        propertyId: 0,
        tenantId: 0,
      },
    }
  },
  methods: {
    removeAttachment(objectToRemove) {
      if (!Array.isArray(this.email.attachments)) {
        throw new Error("First argument must be an array");
      }

      const index = this.email.attachments.indexOf(objectToRemove);
      if (index > -1) {
        this.email.attachments.splice(index, 1); // Remove the object from the array
      }
    },
    truncateString(input, maxLength = 20) {
      if (typeof input !== "string") {
        throw new Error("Input must be a string");
      }
      return input.length > maxLength
          ? input.substring(0, maxLength) + "..."
          : input;
    },
    goToProperty(propertyId) {
      this.$store.state.pageId = propertyId;
      this.$router.push('/addpmsproperty')
    },
    getEmailFormattedDate(date) {
      return moment(date).format(this.dateFormat + " hh:mm A")
    },
    editEmail(email, type) {
      this.email = JSON.parse(JSON.stringify(email));
      switch (type) {
        case 'edit':
          this.addEmailDialog = true;
          break;
        case 'delete':
          this.deleteEmailDialog = true;
          break;
        case 'attach':
          this.viewAttachments = true;
          break
      }
    },
    getTenantName(item) {
      return item.firstName + ' ' + item.surname;
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getProperties();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getProperties();
    },
    async getClients() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') < 1) {
          this.$store.state.loading = true;
          let response = await ClientService.getAll();
          this.clients = response.data.items;
          if (this.clients.length > 0) {
            this.filter.clientId = this.clients[0].id;
          }
        }

      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
      await this.getEmails();
      await this.getContacts();
      await this.getProperties();
    },

    // Emails

    async getEmails() {
      this.loading = true;
      try {

        let response = await emailService.getEmails(this.filter);
        this.emails = response?.data?.items ?? [];
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },
    async addEmail() {
      this.loading = true;
      try {
        if (this.email.contactId < 1) {
          ShowMessage("warning", "Please select a recipient first");
          this.loading = false;
          return;
        }
        if (!this.email.subject || !this.email.body) {
          ShowMessage("warning", "Subject and body are mandatory");
          this.loading = false;
          return;
        }
        this.addEmailDialog = false;
        if (this.email.propertyId < 1) {
          this.email.propertyId = 0
        }
        this.email.clientId = this.filter.clientId ?? 0;
        this.email.emailFrom = this.fromEmail;
        if (this.email.propertyId < 1) {
          this.email.propertyId = 0;
        }
        let response = await emailService.addEmail(this.email);

        if (response.status === 200) {
          ShowMessage("success", "Email added");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email adding failed, try again later");
      }
      this.loading = false;
    },
    async updateEmail() {
      this.loading = true;
      try {
        let response = await emailService.updateEmail(this.email);
        if (response.status === 200) {
          ShowMessage("success", "Email updated");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email updating failed, try again later");
      }
      this.loading = false;
    },
    async deleteEmail() {
      this.loading = true;
      try {
        let response = await emailService.deleteEmail(this.email.id);
        if (response.status === 200) {
          ShowMessage("success", "Email deleted");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email deleting failed, try again later");
      }
      this.loading = false;
    },
    async getContacts() {
      try {

        let response = await contactService.getAllContacts(this.filter.clientId, 0);
        this.contacts = response?.data?.items ?? [];
        if (this.contacts.length > 0) {
          this.email.emailTo = this.contacts[0].id;
        } else {
          this.email.emailTo = "";
        }
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    },
    resetEmail() {
      this.email = {
        "id": 0,
        "emailFrom": "",
        "emailTo": "",
        "copyTo": "",
        "subject": "",
        "body": "Email body goes here",
        "propertyId": null,
        "clientId": null,
        "sentDate": new Date(),
        "status": 0,
        "contactId": null,
        "providerId": null,
        "attachments": [],
        "userId": sessionStorage.getItem("userId"),
      };
      this.propertyDisplay = false;
      this.ccDisplay = false;
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }

        let response = await configService.getByKey(id, "DateFormat");
        this.dateFormat = response.data.value ?? "DD/MM/YYYY";
        let response2 = await configService.getByKey(id, "FromEmail");
        this.fromEmail = response2.data.value ?? "admin@fdesigns.co.uk";
        this.loading = false;

        let response3 = await configService.getByKey(id, "MaxDocumentUpload");
        this.maxFileUploads = parseInt(response3.data.value) ?? 1;
        let response4 = await configService.getByKey(id, "MaxDocumentFileSize");
        this.fileMaxSize = parseInt(response4.data.value) ?? 5;
      } catch (e) {
        console.log(e);
      }
    },
    async getProperties() {
      try {
        this.loading = true;
        let response;

        switch (this.clientMarket) {
          case "Housing Management":
            response = await propertyService.getAll(this.filter.clientId);
            this.properties = response.data.items ?? [];
            break;
          case "Property Management":
            response = await pmsPropertiesService.getAllProperties(this.filter.clientId);
            this.properties = response.data.items ?? [];
            break;
          case "":
            response = await pmsPropertiesService.getAllProperties(this.filter.clientId);
            this.properties = response.data.items ?? [];
            break;
          default:
            this.properties = []
        }

      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    onFileInput(event) {
      const fileList = event.target.files;
      let promises = [];
      if (fileList.length > this.maxFileUploads || this.email.attachments.length > this.maxFileUploads) {
        ShowMessage("error", "Maximum attachments upload limit is "+this.maxFileUploads);
        return;
      }
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        const attachment = {...this.attachment}; // Ensure a new image object for each file
        attachment.fileName = file.name;
        attachment.fileExtension = file.name.split('.').pop();
        attachment.fileSize = file.size;
        attachment.mimeType = file.type;
        if (attachment.fileSize > this.convertMBToBytes(this.fileMaxSize)) {
          ShowMessage("error", attachment.fileName + "'s size is greater than " + this.fileMaxSize + " mb Plz select file between 0 - " + this.fileMaxSize + " mb");

          continue;
        }


        const promise = this.convertFileToBase64(file)
            .then((base64Data) => {
              attachment.fileData = `data:${file.type};base64,${base64Data}`;
              return attachment; // Return the image object after conversion
            })
            .catch((error) => {
              console.error("Error converting image to Base64:", error);
            });

        promises.push(promise);
      }

      Promise.all(promises).then((filesList) => {
        // Ensure `attachments` is an array
        if (!Array.isArray(this.email.attachments)) {
          this.email.attachments = [];
        }

        // Concatenate the new images with the existing ones
        this.email.attachments = [...this.email.attachments, ...filesList];
      });
    },
    convertMBToBytes(mb) {
      return mb * 1024 * 1024;
    },
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result.split(',')[1]); // Get the Base64 string
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    downloadAttachment(attachment) {
      if (!attachment || !attachment.fileData) {
        console.error("Invalid attachment data");
        return;
      }

      const base64Data = attachment.fileData.split(',')[1];
      const binaryData = atob(base64Data);
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([byteArray], {type: attachment.mimeType});

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = attachment.fileName || "attachment";

      downloadLink.click();

      URL.revokeObjectURL(downloadLink.href);
    },
    async getConfig() {
      try {
        let response = await configService.getByKey(this.clientId, "MaxDocumentUpload");
        console.log(response);
        let response2 = await configService.getByKey(this.clientId, "MaxDocumentFileSize");
        console.log(response2);

      } catch (e) {
        console.log(e)
      }
    }

  },
  created() {
    this.getConfigs();
    this.getClients();

  }
}
</script>

<style scoped>
.el-form-item--label-top .el-form-item__label {
  display: block;
  height: auto;
  line-height: 8px !important;
  margin-bottom: 5px !important;
  text-align: left;
  font-size: 12px;
}

.el-form-item {

  margin-bottom: 3px !important;
}
</style>