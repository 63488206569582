import Config from "@/Configuration/Config"
import axios from "axios"

export default {
    addUrl: Config.apiBaseUrl + "customerdoc/add",
    updateUrl: Config.apiBaseUrl + "customerdoc/update",
    getDocsUrl: Config.apiBaseUrl + "customerdoc/getdocs?customerId=",
    getUrl: Config.apiBaseUrl + "customerdoc/get?docId=",
    deleteUrl: Config.apiBaseUrl + "customerdoc/delete?docId=",


    addDoc(doc) {
        return axios.post(this.addUrl, doc)
    },
    updateDoc(doc) {
        return axios.put(this.updateUrl, doc)
    },
    getDocs(filter) {
        return axios.get(this.getDocsUrl + filter.customerId + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getDoc(customerId) {
        return axios.get(this.getUrl + customerId)
    },
    deleteDoc(customerId) {
        return axios.delete(this.deleteUrl + customerId);
    }
}