<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-8">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <el-select filterable v-model="filter.marketId" @change="getPackages" class="w-100">
            <el-option v-for="m in Markets" :key="m.id" :label="m.title" :value="m.id"></el-option>
          </el-select>

          <el-button @click="showAddDialog"
                     class="border-0 mbtn-primary ms-2 text-capitalize">{{
              getMessageByCode('add_pack') ?? 'Add Package'
            }}
          </el-button>
        </div>
      </div>
    </div>
    <el-tabs type="border-card" @tab-click="handleTabClick">
      <el-tab-pane label="Packages">
        <template #label>
          <i class="bi bi-boxes me-1"></i>
          <span>{{ getMessageByCode('packages') ?? 'Packages' }}</span>
        </template>
        <el-table :data="Packages" :default-sort="{ prop: 'title', order: 'ascending' }"
                  :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                  header-cell-class-name="tbl-header"
                  stripe>
          <template #append>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :total="filter.totalCount"
                :page="filter.currentPage"
                :page-size="filter.pageSize"
                :page-sizes="[10, 20, 30, 40]"
                layout=" prev, pager, next,sizes"
                class="bg-gray p-2"
                background
                size="small"
            >
            </el-pagination>
          </template>
          <el-table-column label="#" type="index" align="center" width="55"></el-table-column>
          <el-table-column :label="getMessageByCode('title')??' Title'" prop="title" sortable></el-table-column>
          <el-table-column sortable :label="getMessageByCode('market')??' Market'" prop="marketTitle"></el-table-column>
          <el-table-column sortable :label="getMessageByCode('active')??' Active'" prop="isActive">
            <template #default="scope">
              <el-tag v-if="scope.row.isActive" type="success" size="small">Yes</el-tag>
              <el-tag v-else type="danger" size="small">Yes</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="130">
            <template #default="scope">
              <el-space alignment="center" spacer="|">
                <i
                    class="mgc_edit_line text-primary action-btn"
                    @click="editPackage(scope.row,'edit')"
                ></i>
                <i @click="editPackage(scope.row,'del')"
                   class="mgc_delete_2_line text-danger action-btn"
                ></i>
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Packages">
        <template #label>
          <i class="bi bi-diagram-3 me-1"></i>
          <span class="text-capitalize">{{ getMessageByCode('pack_modu') ?? 'Packages Modules' }}</span>
        </template>
        <div class="container-fluid px-0 py-2">
          <div class="row justify-content-end">
            <div class="col-12 col-md-6 col-lg-4 d-flex">
              <el-select filterable class="w-100" v-model="filter.packageId" @change="getModules">
                <el-option v-for="pkg in Packages" :key="pkg.id" :label="pkg.title" :value="pkg.id"></el-option>
              </el-select>
              <el-button @click="moduleAdd" type="primary" class="ms-2 text-capitalize">
                {{ getMessageByCode('add_modu') ?? 'Add Module' }}
              </el-button>
            </div>
          </div>
        </div>
        <el-table :data="Modules" table-layout="fixed" :default-sort="{ prop: 'moduleTitle', order: 'ascending' }"
                  :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                  header-cell-class-name="tbl-header"
                  stripe>
          <template #append>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :total="filter.totalCount"
                :page="filter.currentPage"
                :page-size="filter.pageSize"
                :page-sizes="[10, 20, 30, 40]"
                layout=" prev, pager, next,sizes"
                class="bg-gray p-2"
                background
                size="small"
            >
            </el-pagination>
          </template>
          <el-table-column label="#" type="index" align="center" width="55"></el-table-column>

          <el-table-column :label="getMessageByCode('title')??'Title'" prop="moduleTitle" sortable>
            <template #default="scope">
              <span>{{ scope.row.moduleTitle }}</span>
            </template>
          </el-table-column>
          <el-table-column sortable :label="getMessageByCode('slug')??'Slug'" prop="moduleSlug">
            <template #default="scope">
              <span>{{ scope.row.moduleSlug }}</span>

            </template>
          </el-table-column>
          <el-table-column sortable :label="getMessageByCode('def_type')??'Default Type'" prop="defaultType"></el-table-column>
          <el-table-column sortable :label="getMessageByCode('def_value')??'Default Value'"
                           prop="defaultValue"></el-table-column>
          <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="130">
            <template #default="scope">
              <el-space alignment="center" spacer="|">
                <i
                    class="mgc_edit_line text-primary action-btn"
                    @click="editModule(scope.row, 'edit')"
                ></i>
                <i @click="editModule(scope.row,'del')"
                   class="mgc_delete_2_line text-danger action-btn"
                ></i>
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>


    <!--    Dialogs-->
    <!--    Edit Dialog-->
    <el-dialog
        draggable
        class="text-capitalize"
        v-model="addPackageDialog"
        :title="packge.id > 0 ? getMessageByCode('update')??'Update' + getMessageByCode('package')??' Package' : getMessageByCode('add_pack')??'Add Package'"
        width="30%"
    >
      <el-form label-position="top">
        <el-form-item>
          <template #label>
            <span class="required">{{ getMessageByCode('market') ?? 'Market' }}</span>
          </template>
          <el-select v-model="packge.marketId" @change="getPackages" class="w-100">
            <el-option v-for="m in Markets" :key="m.id" :label="m.title" :value="m.id" :disabled="m.id < 1">

            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="required">{{ getMessageByCode('title') ?? 'Title' }}</span>
          </template>
          <el-input v-model="packge.title"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button bg text @click="addPackageDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
         <el-button v-if="packge.id === 0" :disabled="!packge.title" type="primary"
                    @click="addPackageDialog = false, addPackage()">
         {{ getMessageByCode('add') ?? 'Add' }}
        </el-button>
        <el-button v-if="packge.id > 0" :disabled="!packge.title" type="primary"
                   @click="addPackageDialog = false, updatePackage()">
        {{ getMessageByCode('update') ?? 'Update' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog
        draggable
        class="text-capitalize"
        v-model="addModuleDialog"
        :title="Module.id > 0 ? getMessageByCode('update')?? 'Update' +getMessageByCode('module')?? 'Module'  : getMessageByCode('add_modu')?? 'Add Module'"
        width="40%"
    >
      <el-form label-position="top" :model="Module" :rules="rules" hide-required-asterisk>
        <div class="row">
          <div class="col-12 col-md-6">
            <el-form-item class="w-100">
              <template #label>
                <span class="required">{{ getMessageByCode('market') ?? 'Market' }}</span>
              </template>
              <el-select v-model="packge.marketId" @change="getAddDialogPackages()" class="w-100">
                <el-option v-for="m in dialogMarkets" :key="m.id" :label="m.title" :value="m.id" :disabled="m.id < 1">

                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item class="w-100" prop="">
              <template #label>
                <span class="required">{{ getMessageByCode('package') ?? 'Package' }}</span>
              </template>
              <el-select v-model="Module.packageId" @change="getPackages" class="w-100">
                <el-option v-for="m in dialogPakages" :key="m.id" :label="m.title" :value="m.id" :disabled="m.id < 1">

                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item class="w-100">
              <template #label>
                <span class="required">{{ getMessageByCode('title') ?? 'Title' }}</span>
              </template>
              <el-input v-model="Module.moduleTitle"></el-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item class="w-100" prop="moduleSlug" :rules="rules.moduleName">
              <template #label>
                <span class="required">{{ getMessageByCode('slug') ?? 'Slug' }}</span>
              </template>
              <el-input placeholder="[MK_PckName]" @keydown.space.prevent v-model="Module.moduleSlug"></el-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item class="w-100">
              <template #label>
                <span class="required">{{ getMessageByCode('def_type') ?? 'Default Type' }}</span>
              </template>
              <!--              <el-input v-model="Module.defaultType"></el-input>-->
              <el-select v-model="Module.defaultType" class="w-100">
                <el-option
                    v-for="item in ModuleTypeHeaders"
                    :key="item.optId"
                    :label="item.optTitle"
                    :value="item.optTitle"
                    style="font-weight: normal; color: #0a0a0a"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item class="w-100">
              <template #label>
                <span class="required">{{ getMessageByCode('def_value') ?? 'Default Value' }}</span>
              </template>
              <el-input v-model="Module.defaultValue"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button bg text @click="addModuleDialog = false">Cancel</el-button>
         <el-button v-if="Module.id === 0" :disabled="!Module.moduleSlug" type="primary"
                    @click="addModuleDialog = false, addModule()">
        {{ getMessageByCode('add') ?? 'Add' }}
        </el-button>
        <el-button v-if="Module.id > 0" :disabled="!Module.moduleSlug" type="primary"
                   @click="addModuleDialog = false, updateModule()">
       {{ getMessageByCode('update') ?? 'Update' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog
        draggable
        v-model="deleteDialog"
        title="Delete"
        width="30%"
    >
      <span>Are you sure? You want to delete <span
          class="mfw-bold text-danger">{{ packge.title }}</span> package? </span>
      <template #footer>
      <span class="dialog-footer">
        <el-button bg text @click="deleteDialog = false">Cancel</el-button>
        <el-button :disabled="!packge.title" class="bg-danger text-white border-0"
                   @click="deleteDialog = false, deletePackage()">
        Delete
        </el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog
        draggable
        v-model="deleteModuleDialog"
        title="Delete"
        width="30%"
    >
      <span>Are you sure? You want to delete <span class="mfw-bold text-danger">{{ Module.moduleSlug }}</span> module? </span>
      <template #footer>
      <span class="dialog-footer">
        <el-button bg text @click="deleteModuleDialog = false">Cancel</el-button>
        <el-button :disabled="!Module.moduleSlug" class="bg-danger text-white border-0"
                   @click="deleteModuleDialog = false, deleteModule()">
        Delete
        </el-button>
      </span>
      </template>
    </el-dialog>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import marketService from "@/Services/ComponentsServices/marketService";
import {ShowMessage} from "@/Utility/Utility";
import packageService from "@/Services/ComponentsServices/packageService";
import optionService from "@/Services/ComponentsServices/optionService";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "PackagesList",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      addPackageDialog: false,
      addModuleDialog: false,
      deleteDialog: false,
      deleteModuleDialog: false,
      packages: true,
      Modules: [],
      Packages: [],
      dialogPakages: [],
      translations: [],
      ModuleTypeHeaders: [],
      dialogMarkets: [],
      Markets: [
        {
          title: "All", id: 0
        }
      ],
      Module: {
        "id": 0,
        "moduleSlug": "",
        "moduleTitle": "",
        "packageId": 0,
        "defaultType": "",
        "defaultValue": "",
      },

      packge: {
        "id": 0,
        "title": "",
        "marketId": 1,
        "marketTitle": "",
        "isActive": true,
        "clientId": 0
      },
      rules: {
        moduleName: [
          {required: true, message: 'Module Slug is required', trigger: 'blur'},
          {validator: this.validateModuleSlug, trigger: ['blur', 'change']}
        ],
        // Other form rules go here
      },
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
        marketId: 0,
        packageId: 1,
      },
    }
  },
  methods: {
    moduleAdd() {
      this.resetModule();
      this.addModuleDialog = true;
    },
    resetModule() {
      this.Module = {
        "id": 0,
        "moduleSlug": "",
        "moduleTitle": "",
        "packageId": 0,
        "defaultType": "",
        "defaultValue": "",
      };
      if (this.Packages.length > 0) {
        this.Module.packageId = this.Packages[0].id;
      } else {
        this.Module.packageId = "";
      }
    },
    validateModuleSlug(rule, value, callback) {
      const regex = /^\[MK_[A-Za-z0-9]+\]$/; // Regular expression for [MK_ModuleName]
      if (!value.match(regex)) {
        callback(new Error('Format should be [MK_ModuleName]'));
      } else {
        callback();
      }
    },
    editRow(row) {
      row.editing = true;
    },
    editModule(module, type) {
      this.Module = {...module};
      if (type === "edit") {
        this.addModuleDialog = true;
      } else {
        this.deleteModuleDialog = true;
      }

    },
    handleTabClick(tab) {
      if (Number(tab.index) === 0) {
        this.packages = true;
        let myObject = {
          title: "All", id: 0
        }
        if (this.Markets && Array.isArray(this.Markets)) {
          const exists = this.Markets.some(item => item.id === myObject.id && item.title === myObject.title);
          if (!exists) {
            this.Markets.splice(0, 0, myObject);
            this.filter.marketId = this.Markets[0].id;
          }
        }

        this.getPackages();
      } else if (Number(tab.index) === 1) {
        // this.packages = false;
        // this.Markets.splice(0, 1);
        // this.filter.marketId = this.Markets[0].id;
        // this.getPackages();
        this.getModules();
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getPackages();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getPackages();
    },
    editPackage(packge, type) {
      this.packge = {...packge};
      if (type === "edit") {
        this.addPackageDialog = true;
      } else {
        this.deleteDialog = true;
      }

    },
    async addPackage() {
      try {
        this.loading = true;
        let response = await packageService.add(this.packge);
        if (response.status === 200) {
          this.getPackages();
          ShowMessage("success", "Package added successfully");
        }
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },

    async updatePackage() {
      try {
        this.loading = true;
        let response = await packageService.update(this.packge);
        if (response.status === 200) {
          this.getPackages();
          ShowMessage("success", "Package update successfully");
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
    async updateModule() {
      try {
        this.loading = true;
        let response = await packageService.updateModule(this.Module);
        if (response.status === 200) {
          await this.getModules();
          ShowMessage("success", "Module update successfully");
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
    async addModule() {
      try {
        if (this.Module.moduleSlug === "") {
          ShowMessage("error", "Module slug is required");
          return;
        }
        this.loading = true;
        let response = await packageService.addModule(this.Module);
        if (response.status === 200) {
          this.getModules();
          ShowMessage("success", "Module added successfully");
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
    async deletePackage() {
      try {
        this.loading = true;
        let response = await packageService.delete(this.packge.id);
        if (response.status === 200) {
         await this.getPackages();
          ShowMessage("success", "Package delete successfully");
        }
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong");
      }
    },
    async getModuleTypeHeaders() {
      try {
        let response = await optionService.getOptionsByHeaderId(0, 28);
        this.ModuleTypeHeaders = response.data ?? [];
        /* if (this.Categories.length > 0) {
           this.Doc.noteCategoryId = this.Categories[0].optId;
         }*/
      } catch (e) {
        console.log()
      }
    },
    async deleteModule() {
      try {
        this.loading = true;
        let response = await packageService.deleteModule(this.Module.id);
        if (response.status === 200) {
          this.getModules();
          ShowMessage("success", "Module delete successfully");
        }
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong");
      }
    },
    async getAddDialogPackages() {
      let filter = JSON.parse(JSON.stringify(this.filter));
      filter.marketId = this.packge.marketId;
      let response = await packageService.all(filter);
      this.dialogPakages = response.data.items ?? [];
      if (this.dialogPakages.length > 0) {
        this.Module.packageId = this.dialogPakages[0].id;
      }
    },
    async getPackages() {
      try {
        this.loading = true;
        let response = await packageService.all(this.filter)
        this.Packages = response.data.items ?? [];
        if (this.Packages.length > 0) {
          this.filter.packageId = this.Packages[0].id;
        }
        await this.getModules();

        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage;
        this.filter.pageSize = response.data.pageSize;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getModules() {
      try {
        this.loading = true;
        let response = await packageService.getModules(this.filter.packageId)
        this.Modules = response.data ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize ?? response.data.pageSize === 0 ? 10 : response.data.pageSize || 10;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getMarkets() {
      try {
        this.loading = true;
        let response = await marketService.getAll();
        this.Markets = this.Markets.concat(response.data.items);
        this.dialogMarkets = response?.data?.items ?? [];
        if (this.dialogMarkets.length > 0) {
          this.filter.marketId = this.dialogMarkets[0].id;
          await this.getAddDialogPackages();
        }
        this.loading = false;
        await this.getPackages();
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    showAddDialog() {
      this.packge = {
        "id": 0,
        "title": "",
        "marketId": 1,
        "marketTitle": "",
        "isActive": true,
        "clientId": 0
      },
          this.addPackageDialog = true;
    }
    ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.getModuleTypeHeaders();
    this.getMarkets();
  }
}
</script>

<style scoped>

</style>