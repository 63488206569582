import Config from "@/Configuration/Config";
import axios from 'axios'

export default {
    allUrl: Config.apiBaseUrl + "contact/getcontacts?clientId=",
    getAllUrl: Config.apiBaseUrl + "contact/getallcontacts?clientId=",

    addUrl: Config.apiBaseUrl + "contact/add",
    updateUrl: Config.apiBaseUrl + "contact/update",
    deleteUrl: Config.apiBaseUrl + "contact/delete?contactId=",
    getContactUrl: Config.apiBaseUrl + "contact/get?contactId=",
    getCategoriesUrl: Config.apiBaseUrl + "contact/getcategories?clientId=",
    getTenantsAndLandlordsUrl: Config.apiBaseUrl + "contact/gettenantsandlandlords?clientId=",

    // types
    addCategoryUrl: Config.apiBaseUrl + "contact/addcategory",
    updateCategoryUrl: Config.apiBaseUrl + "contact/updatecategory",
    deleteCategoryUrl: Config.apiBaseUrl + "contact/deletecategory?catId=",

    all(filter) {
        return axios.get(this.allUrl + filter.clientId + "&category=" + filter.category + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAllContacts(client, category) {
        return axios.get(this.getAllUrl + client + "&category=" + category)
    },
    add(contact) {
        return axios.post(this.addUrl, contact)
    },
    update(contact) {
        return axios.put(this.updateUrl, contact)
    },

    delete(contactId) {
        return axios.delete(this.deleteUrl + contactId)
    },

    getContact(contactId) {
        return axios.get(this.getContactUrl + contactId)
    },
    getAllCategories(clientId) {
        return axios.get(this.getCategoriesUrl+clientId);
    },


    addType(type) {
        return axios.post(this.addCategoryUrl, type);
    },
    updateType(type) {
        return axios.put(this.updateUrl, type);
    },
    deleteType(typeId) {
        return axios.delete(this.deleteCategoryUrl + typeId);
    },

    getTenantsAndLandlords(clientId) {
        return axios.get(this.getTenantsAndLandlordsUrl + clientId)
    }


}