<template>
  <div class="h-100 d-flex justify-content-center align-items-center">
    <el-result sub-title="Page not found." title="404">
      <template #icon>
        <img alt="404" src="../../assets/svg/pages/404.svg" width="150"/>
      </template>
      <template #extra>
        <router-link to="/dashboard">
          <button v-if="isAdmin" class="btn btn-sm btn-danger">Back To Home</button>
        </router-link>
        <router-link to="/housing">
          <button v-if="!isAdmin" class="btn btn-sm btn-danger">Back To Home</button>
        </router-link>
      </template>
    </el-result>
  </div>
</template>

<script>
import {isAdmin} from "@/components/Authentication/Authenticate";

export default {
  name: "PageNotFound",
  data(){
    return{
      isAdmin: isAdmin(),
    }
  }
}
</script>

<style scoped>

</style>