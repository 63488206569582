<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row align-items-center py-2">
        <div class="col-12 col-md-8">
          <BreadCrumb :id="customer.id" :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link to="customers">
            <el-button text bg class="btn btn-light">
              <i class="bi bi-chevron-left me-2"></i>
              <span>{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
            </el-button>
          </router-link>
          <el-button v-if="!edit" type="primary" class="btn btn-light ms-2" @click="edit = !edit">
            <span>{{ getMessageByCode('edit') ?? 'Edit' }}</span>
          </el-button>
          <el-button v-if="edit && customer.id > 0" type="primary" class="btn btn-light ms-2" @click="edit = !edit">
            <span>{{ getMessageByCode('view') ?? 'View' }}</span>
          </el-button>

          <el-button v-if="customer.id < 1 && edit"
                     :disabled="customer.companyType < 1 || !customer.registrationNumber || !customer.vatRegistrationNumber"
                     type="primary" class="btn btn-light ms-2" @click="addCustomer()">
            <span>{{ getMessageByCode('add_cust') ?? 'Add Customer' }}</span>
          </el-button>
          <el-button v-show="customer.id > 0 && edit"
                     :disabled="customer.companyType < 1 || !customer.registrationNumber || !customer.vatRegistrationNumber"
                     type="primary" class="btn btn-light ms-2"
                     @click="updateCustomer()">
            <span>{{ getMessageByCode('upd_cust') ?? 'Update Customer' }}</span>
          </el-button>
        </div>
      </div>
    </div>
    <div class="container-fluid m-0 p-0">
      <el-tabs v-model="activeTab" class="demo-tabs" type="border-card" @tab-click="handleTabClick">
        <el-tab-pane :name="0" label="Personal Info">
          <template #label>
            <div>
              <i class="bi bi-person-fill"></i>
              <span class="ms-2">{{ getMessageByCode("pers_info") ?? "Personal Info" }}</span>
            </div>
          </template>
          <div class="row  d-flex align-items-md-stretch">
            <div class="col-12 col-lg-4">
              <div class="card  h-md-100">
                <div class="card-header">
                  <span>Personal Info</span>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item v-if="clientId === null || clientId === 0"
                                      :label="getMessageByCode('client') ?? 'Client'" required>
                          <el-select :disabled="!edit" v-model="customer.clientId" filterable class="w-100"
                                     placeholder="Select">
                            <el-option v-for="item in clients" :key="item.id" :label="item.name" :value="item.id"/>
                          </el-select>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('first_name') ?? 'First Name'" required>
                          <el-input :disabled="!edit" v-model="customer.firstName"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('middle_name') ?? 'Middle Name'" required>
                          <el-input :disabled="!edit" v-model="customer.middleName"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('last_name') ?? 'Last Name'" required>
                          <el-input :disabled="!edit" v-model="customer.lastName"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('dob') ?? 'Date of Birth'">
                          <el-date-picker :format="dateFormat" :disabled="!edit" class="w-100" :editable="false"
                                          v-model="customer.dob"></el-date-picker>
                        </el-form-item>
                      </el-form>
                    </div>

                    <div class="col-12">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('mobile') ?? 'Mobile'" required>
                          <el-input :disabled="!edit" class="w-100" :editable="false"
                                    v-model="customer.mobile"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('email') ?? 'Email'" required>
                          <el-input :disabled="!edit" class="w-100" :editable="false"
                                    v-model="customer.email"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('country') ?? 'Country'">
                          <el-select :disabled="!edit" v-model="customer.county" filterable placeholder="Select"
                                     class="w-100">
                            <el-option v-for="item in countries" :key="item.optId" :label="item.optTitle"
                                       :value="item.optTitle" style="font-weight: normal; color: #0a0a0a"/>
                          </el-select>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-8 ">
              <div class="card" style="height: 100% !important;">
                <div class="card-header">
                  <span>Company Info</span>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('comp') ?? 'Company'" required>
                          <el-input :disabled="!edit" v-model="customer.companyName"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12 col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item label-position="top" require-asterisk-position="right"
                                      :label="getMessageByCode('comp_size') ?? 'Company Size'" required>
                          <el-select :disabled="!edit" class="w-100" v-model="customer.companyType">
                            <el-option v-for="item in companySizes" :key="item.optId" :value="item.optId"
                                       :label="item.optTitle">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12 col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('reg_num') ?? 'Registration Number'">
                          <el-input :disabled="!edit" v-model="customer.registrationNumber"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12 col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('v_reg_num') ?? 'Vat Registration Number'">
                          <el-input :disabled="!edit" v-model="customer.vatRegistrationNumber"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12  col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('address') +' 1' ?? 'Address ' + 1">
                          <el-input :disabled="!edit" v-model="customer.address1"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12 col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('address') +' 2' ?? 'Address ' + 2">
                          <el-input :disabled="!edit" v-model="customer.address2"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12  col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('address') +' 3' ?? 'Address ' + 3">
                          <el-input :disabled="!edit" v-model="customer.address3"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12 col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('region')  ?? 'Region'">
                          <el-input :disabled="!edit" v-model="customer.region"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12  col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('town') ?? 'Town / City'">
                          <el-input :disabled="!edit" v-model="customer.town"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12 col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('postcode')  ?? 'Postcode'">
                          <el-input :disabled="!edit" v-model="customer.postCode"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12 col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('telephone')  ?? 'Telephone'">
                          <el-input :disabled="!edit" v-model="customer.telephone"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12 col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('webiste')  ?? 'Webiste'">
                          <el-input :disabled="!edit" v-model="customer.website"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div class="col-12 col-lg-6">
                      <el-form label-position="top" require-asterisk-position="right">
                        <el-form-item :label="getMessageByCode('comp_email') ?? 'Company Email'">
                          <el-input :disabled="!edit" v-model="customer.companyEmail"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :name="1" v-if="customer.id > 0">
          <template #label>
            <div>
              <i class="bi bi-clipboard-check-fill"></i>
              <span class="ms-2">{{ getMessageByCode("notes") ?? '' }}</span>
            </div>
          </template>
          <div class="d-flex justify-content-end mb-2">
            <el-button type="primary" @click="addNoteDialog = true">{{
                getMessageByCode("add_note") ?? 'Add Note'
              }}
            </el-button>
          </div>
          <el-table :data="notes" :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header" stripe>
            <template #append>
              <el-pagination @size-change="handleSizeChange(filter.pageSize,'notes')"
                             @current-change="handleCurrentChange(filter.currentPage,'notes')"
                             :total="filter.totalCount" :page="filter.currentPage" :page-size="filter.pageSize"
                             :page-sizes="[10, 20, 30, 40]" layout=" prev, pager, next,sizes" class="bg-gray p-2"
                             background
                             size="small">
              </el-pagination>
            </template>
            <el-table-column label="#" type="index" width="55" sortable></el-table-column>

            <el-table-column :label="getMessageByCode('category') ?? 'Category'" prop="category"
                             width="150">
              <template #default="scope">
                <span>{{ getNotesCategoryName(scope.row.noteCategoryId)}}</span>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('note') ?? 'Note'" prop="note" min-width="200"/>
            <el-table-column :label="getMessageByCode('addedBy') ?? 'Added By'" prop="addedBy" width="200"/>
            <el-table-column :label="getMessageByCode('date') ?? 'Date'" prop="note" width="200">
            <template #default="scope">
              <span>{{getFormattedDate(scope.row.addedDate)}}</span>
            </template>
            </el-table-column>
            <el-table-column align="center" fixed="right"  :label="getMessageByCode('actions') ?? 'Actions'" width="100">
              <template #default="scope">
                <div class="d-flex justify-content-center">


                  <el-tooltip content="Edit">
                    <i class="mgc_edit_line action-btn text-primary" @click="takeNote(scope.row, 'edit')"></i>
                  </el-tooltip>
                  <el-tooltip content="Delete">
                    <i class="mgc_delete_2_line action-btn text-danger ms-2" @click="takeNote(scope.row, 'delete')"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :name="2" v-if="customer.id > 0">
          <template #label>
            <div>
              <i class="bi bi-files"></i>
              <span class="ms-2">{{ getMessageByCode("documents") ?? 'Documents' }}</span>
            </div>
          </template>
          <div class="d-flex justify-content-end mb-2" @click="addDocDialog = true">
            <el-button type="primary">{{ getMessageByCode("add_doc") ?? 'Add Document' }}</el-button>
          </div>
          <el-table :data="documents" :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'"
                    border
                    header-cell-class-name="tbl-header" stripe>
            <template #append>
              <el-pagination @size-change="handleSizeChange(filter.pageSize,'docs')"
                             @current-change="handleCurrentChange(filter.currentPage,'docs')"
                             :total="filter.totalCount" :page="filter.currentPage" :page-size="filter.pageSize"
                             :page-sizes="[10, 20, 30, 40]" layout=" prev, pager, next,sizes" class="bg-gray p-2"
                             background
                             size="small">
              </el-pagination>
            </template>
            <el-table-column label="#" type="index" width="55" sortable></el-table-column>
            <el-table-column :label="getMessageByCode('file_name') ?? 'File Name'" prop="fileName" min-width="150"
                             sortable>
            </el-table-column>
            <el-table-column :label="getMessageByCode('ext') ?? 'Extension'" prop="fileExtension" min-width="150"/>
            <el-table-column :label="getMessageByCode('mime_type') ?? 'Mime Type'" prop="mimeType" min-width="200"/>
            <el-table-column :label="getMessageByCode('size') ?? 'Size'" prop="size" min-width="150">
              <template #default="scope">
                <span>{{ formatSize(scope.row.size) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right"  :label="getMessageByCode('actions') ?? 'Actions'" width="100">
              <template #default="scope">
                <el-tooltip content="Delete">
                  <i class="mgc_delete_2_line action-btn text-danger ms-2" @click="takeDoc(scope.row, 'delete')"></i>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :name="3" :label="getMessageByCode('accounts') ?? 'Accounts'" v-if="customer.id > 0">
          <template #label>
            <div>
              <i class="bi bi-person-fill"></i>
              <span class="ms-2">{{ getMessageByCode("accounts") ?? "Accounts" }}</span>
            </div>
          </template>
          <div class="d-flex justify-content-end mb-2">
            <el-button type="primary" @click="addAccountDialog = true">{{
                getMessageByCode("add_account") ?? 'Add Account'
              }}
            </el-button>
          </div>
          <el-table :data="accounts" :fit="true" :flexible="true"
                    :selectable="(row) => row.status !== 'disabled'" border header-cell-class-name="tbl-header" stripe>
            <template #append>
              <el-pagination @size-change="handleSizeChange(filter.pageSize,'accounts')"
                             @current-change="handleCurrentChange(filter.currentPage,'accounts')"
                             :total="filter.totalCount" :page="filter.currentPage" :page-size="filter.pageSize"
                             :page-sizes="[10, 20, 30, 40]" layout=" prev, pager, next,sizes" class="bg-gray p-2"
                             background
                             size="small">
              </el-pagination>
            </template>
            <el-table-column label="#" type="index" width="55" sortable></el-table-column>
            <el-table-column :label="getMessageByCode('title') ?? 'Title'" prop="title" min-width="150"/>
            <el-table-column :label="getMessageByCode('year_start') ?? 'Year Start'" min-width="200">
              <template #default="scope">
                <span>{{ getFormattedDate(scope.row.yearStart) }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('year_end') ?? 'Year End'" min-width="200">
              <template #default="scope">
                <span>{{ getFormattedDate(scope.row.year_end) }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('prep_date') ?? 'Preparation Date'" min-width="200">
              <template #default="scope">
                <span>{{ getFormattedDate(scope.row.preparationDate) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :name="4" label="Transactions" v-if="customer.appUserId > 0">
          <el-table :data="customer?.transactions" :fit="true" :flexible="true"
                    :selectable="(row) => row.status !== 'disabled'" border header-cell-class-name="tbl-header" stripe>
            <template #append>
              <el-pagination @size-change="handleSizeChange(filter.pageSize,'transactions')"
                             @current-change="handleCurrentChange(filter.currentPage,'transactions')"
                             :total="filter.totalCount" :page="filter.currentPage" :page-size="filter.pageSize"
                             :page-sizes="[10, 20, 30, 40]" layout=" prev, pager, next,sizes" class="bg-gray p-2"
                             background
                             size="small">
              </el-pagination>
            </template>
            <el-table-column label="#" type="index" width="55" sortable></el-table-column>
            <el-table-column :label="getMessageByCode('category') ?? 'Category'" prop="categoryName" width="200"
                             sortable>
            </el-table-column>
            <el-table-column :label="getMessageByCode('description') ?? 'Description'" prop="description"
                             min-width="200"/>
            <el-table-column :label="getMessageByCode('type') ?? 'Type'" prop="isExpense" width="100">
              <template #default="scope">
                <span v-if="scope.row.isExpense">Expense</span>
                <span v-else>Income</span>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('date') ?? 'Date'" width="130">
              <template #default="scope">
                <span>{{ getFormattedDate(scope.row.createdDate) }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('amount') ?? 'Amount (' + currency+')'" prop="amount" width="120">
              <template #default="scope">
                <div class="text-end">
                  <span>{{parseFloat(scope.row.amount).toFixed(2) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('action') ?? 'Action'" fixed="right" width="80">
              <template #default="scope">
                <div class="text-center">
                  <n-image
                      width="20"
                      :src="getBase64Image(scope.row.image)"
                  />
                  <!--                  <el-tooltip content="Transaction Images">
                                      <i class="bi bi-image text-primary action-btn" @click="takeTransaction(scope.row)"></i>
                                    </el-tooltip>-->
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>


    <!--    Dialogs-->
    <el-dialog v-model="addNoteDialog" style="width: auto; max-width: 500px">
      <template #header>
        <span>{{
            note.id > 0 ? getMessageByCode("edit_note") ?? 'Edit Note' : getMessageByCode("add_note") ?? 'Add Note'
          }}</span>
      </template>
      <template #default>
        <el-form label-position="top">
          <el-form-item>
            <el-select v-model="note.noteCategoryId">
              <el-option v-for="cat in notesCategories" :key="cat.id" :label="cat.name" :value="cat.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="note.note" type="textarea" :rows="3"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addNoteDialog = false">
            Cancel
          </el-button>
          <el-button v-if="note.id < 1" type="primary" @click="addNote()">
            Add Note
          </el-button>
          <el-button v-else type="primary" @click="updateNote()">
            Update note
          </el-button>
        </div>
      </template>

    </el-dialog>
    <el-dialog v-model="deleteNoteDialog" style="width: auto; max-width: 500px">
      <template #header>
        <span>{{
            getMessageByCode("delete_note") ?? 'Delete Note'
          }}</span>
      </template>
      <template #default>
        <span>{{
            getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
          }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold text-danger"> {{
              note.name
            }}</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteNoteDialog = false">
            Cancel
          </el-button>
          <el-button type="danger" @click="deleteNote()()">
            Delete Note
          </el-button>
        </div>
      </template>

    </el-dialog>

    <el-dialog v-model="addDocDialog" :title="getMessageByCode('add_doc') ?? 'Add Document'"
               style="width: 90%; max-width: 500px">
      <template #default>
        <el-form label-position="top">
          <el-form-item :label="getMessageByCode('doc_cat') ?? 'Document Category'">
            <el-select v-model="doc.categoryId" class="w-100">
              <el-option v-for="cat in documentCategories" :key="cat.id" :label="cat.optTitle"
                         :value="cat.optId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="getMessageByCode('select_doc') ?? 'Select File'">
            <el-input v-model="doc.fileName">
              <template #prepend>
                <el-upload :auto-upload="false" :on-change="handleFileChange" :show-file-list="false" class="mb-0">
                  <template #trigger>
                    <el-button type="primary">{{ getMessageByCode('select_file') ?? 'select file' }}</el-button>
                  </template>
                </el-upload>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addDocDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button type="primary" class="ms-2" @click="addDoc()">{{ getMessageByCode('add') ?? 'Add' }}</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="deleteDocDialog" style="width: auto; max-width: 500px">
      <template #header>
        <span>{{
            getMessageByCode("delete_doc") ?? 'Delete Document'
          }}</span>
      </template>
      <template #default>
        <span>{{
            getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
          }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold text-danger"> {{
              doc.fileName
            }}</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteDocDialog = false">
            Cancel
          </el-button>
          <el-button type="danger" @click="deleteDoc()">
            Delete
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addAccountDialog" style="width: auto; max-width: 500px"
               :title="getMessageByCode('add_acc') ?? 'Add Account'">
      <template #default>
        <el-form label-position="top">
          <el-form-item :label="getMessageByCode('title') ??'Title'">
            <el-input v-model="account.title" :maxlength="250" show-word-limit type="textarea" :rows="3">
              >
            </el-input>
          </el-form-item>
          <el-form-item :label="getMessageByCode('fin_start_year') ??'Financial Start Year'">
            <el-date-picker class="w-100" :format="dateFormat"
                            v-model="account.yearStart"
                            type="date"
                            placeholder="Select Start Year"
                            :editable="false"
                            @change="handleStartDateChange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item :label="getMessageByCode('fin_end_year') ??'Financial End Year'">
            <el-date-picker class="w-100" :format="dateFormat"
                            v-model="account.yearEnd"
                            :editable="false"
                            type="date"
                            placeholder="Select End Year"
                            @change="handleEndDateChange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item :label="getMessageByCode('prep_date') ??'Preparation Date'">
            <el-date-picker class="w-100" :format="dateFormat"
                            v-model="account.preparationDate"
                            type="date"
                            :editable="false"
                            placeholder="Select Preparation Date"

            ></el-date-picker>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addAccountDialog = false">
            Cancel
          </el-button>
          <el-button type="primary" @click="addAccount()">
            Add
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="showTransactionImages" title="Transaction Images" style="width: auto; max-width: 600px">
      <template #default>
        <el-image
            style="width: 100px; height: 100px"
            v-for="image in transaction.images" :key="image.id"
            :src="image.fileData"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :preview-src-list="getImagesList()"
            :initial-index="4"
            fit="cover"
        />
      </template>
    </el-dialog>
    <n-spin v-if="loading" size="small" stroke="#000" :stroke-width="15" description="Loading"
            class="cstm-spin"></n-spin>
  </div>
</template>
<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {getLanguage} from "@/Utility/getLanguage";
import customerService from "@/Services/ComponentsServices/customerService";

import moment from "moment";
import configService from "@/Services/ComponentsServices/configService";
import clientService from "@/Services/ComponentsServices/clientService";
import optionService from "@/Services/ComponentsServices/optionService";
import {ShowMessage} from "@/Utility/Utility";
import noteService from "@/Services/ComponentsServices/noteService";
import {NSpin, NImage} from "naive-ui"
import customerNoteService from "@/Services/ComponentsServices/customerNoteService";
import documentService from "@/Services/ComponentsServices/documentService";
import customerDocumentService from "@/Services/ComponentsServices/customerDocumentService";


export default {
  name: "CustomerView",
  components: {NImage, BreadCrumb, NSpin},
  data() {
    return {
      loading: false,
      edit: true,
      activeTab: 0,
      showTransactionImages: false,
      addNoteDialog: false,
      addAccountDialog: false,
      deleteNoteDialog: false,
      addDocDialog: false,
      deleteDocDialog: false,
      translations: [],
      countries: [],
      dateFormat: "DD/MM/YYYY",
      currency: "£",
      currencyPosition: "left",
      clientId: Number(sessionStorage.getItem("clientId")),
      clients: [],
      documentCategories: [],
      documents: [],
      account: {
        "id": 0,
        "title": "",
        "yearStart": new Date(),
        "yearEnd": new Date(),
        "preparationDate": new Date(),
        "customerId": this.$store.state.pageId
      },
      accounts: [],
      doc: {
        "id": 0,
        "customerId": this.$store.state.pageId,
        "categoryId": "",
        "fileName": "",
        "fileType": 0,
        "fileExtension": "",
        "mimeType": "",
        "object": "",
        "size": 0,

      },
      note: {
        "id": 0,
        "noteCategoryId": "",
        "typeId": 0,
        "note": "",
        "customerId": this.$store.state.pageId
      },
      notes: [],
      notesCategories: [],
      customer: {
        "id": 0,
        "clientId": Number(sessionStorage.getItem("clientId")),
        "companyName": "",
        "companyType": 0,
        "registrationNumber": "",
        "vatRegistrationNumber": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "region": "",
        "town": "",
        "postCode": "",
        "telephone": "",
        "companyEmail": "",
        "website": "",
        "firstName": "",
        "middleName": "",
        "lastName": "",
        "dob": new Date(),
        "mobile": "",
        "email": "",
        "country": "",
        "isActive": true,
        "isDeleted": false,
        "deletedBy": "",
        "appUserId": 0,
        "createdDate": new Date(),
        "addedBy": "",
        "modifiedBy": "",
        "modifiedDate": null
      },
      transactions: [],
      companySizes: [],
      transaction: {},
      filter: {
        clientId: Number(sessionStorage.getItem("clientId") ?? 0),
        customerId: this.$store.state.pageId ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: -1
      },
    }
  },
  mounted() {
    // Initialize yearEnd to be one year after yearStart
    this.account.yearEnd = this.addOneYear(this.account.yearStart);
  },
  methods: {
    getNotesCategoryName(id){
      return this.notesCategories.find(x => x.id === id).name ?? "";
    },
    getBase64Image(imgData) {
      if (imgData === '' || imgData === null || imgData === undefined) {
        return "";
      }
      if (!imgData.startsWith("data:image/png;base64,")) {
        return "data:image/png;base64," + imgData;
      }
      return imgData;
    },
    getImagesList() {
      let images = [];
      this.transaction.images.forEach(e => {
        images.push(e.fileData)
      })
      return images;
    },
    handleStartDateChange() {
      // Ensure yearEnd is always one year after yearStart
      this.account.yearEnd = this.addOneYear(this.account.yearStart);
    },
    handleEndDateChange() {
      // Ensure yearStart is always one year before yearEnd
      this.account.yearStart = this.subtractOneYear(this.account.yearEnd);
    },
    addOneYear(date) {
      const newDate = new Date(date);
      newDate.setFullYear(newDate.getFullYear() + 1);
      return newDate;
    },
    subtractOneYear(date) {
      const newDate = new Date(date);
      newDate.setFullYear(newDate.getFullYear() - 1);
      return newDate;
    },
    takeNote(note, type) {
      this.note = JSON.parse(JSON.stringify(note));
      switch (type) {
        case "delete":
          this.deleteNoteDialog = true;
          break;
        case "edit":
          this.addNoteDialog = true;
          break;
      }
    },
    takeDoc(doc, type) {
      this.doc = JSON.parse(JSON.stringify(doc));
      switch (type) {
        case "delete":
          this.deleteDocDialog = true;
          break;
        case "edit":
          this.addDocDialog = true;
          break;
      }
    },
    takeTransaction(transaction) {
      this.transaction = JSON.parse(JSON.stringify(transaction));
      this.showTransactionImages = true;
    },
    formatSize(sizeBytes) {
      if (sizeBytes < 1024) {
        return `${sizeBytes} bytes`;
      } else if (sizeBytes < 1024 ** 2) {
        return `${(sizeBytes / 1024).toFixed(2)} KB`;
      } else if (sizeBytes < 1024 ** 3) {
        return `${(sizeBytes / (1024 ** 2)).toFixed(2)} MB`;
      } else {
        return `${(sizeBytes / (1024 ** 3)).toFixed(2)} GB`;
      }
    },
    takeRow(transaction) {
      this.transaction = JSON.parse(JSON.stringify(transaction));
    },
    handleFileChange(file) {
      this.doc.fileName = file.raw.name;
      this.doc.size = file.raw.size;
      this.doc.fileExtension = file.raw.name.split('.').pop();
      this.doc.mimeType = file.raw.type;
      try {
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = btoa(new Uint8Array(reader.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            this.doc.object = base64String;
          };
          reader.readAsArrayBuffer(file.raw);
        }
      } catch (error) {
        console.error(error);
      }
    }
    ,
    getFormattedDate(date) {
      if (date === null || date === undefined) {
        return "";
      }
      return moment.utc(date).local().format(this.dateFormat);
    },
    handleTabClick(tabName) {
      let tab = Number(tabName.index);

      switch (tab) {
        case 0:
          this.getCustomer(this.$store.state.pageId);
          break;
        case 1:
          this.getCustomerNotes();
          break;
        case 2:
          this.getCustomerDocuments();
          this.getDocumentCategories();
          break;
        case 3:
          this.getAccounts();
          break;
      }
    },
    handleSizeChange(newSize, type) {
      this.filter.pageSize = newSize;
      switch (type) {
        case 'notes':
          this.getCustomerNotes();
          break;
        case 'docs':
          this.getCustomerDocuments();
          break;
        case 'accounts':
          this.getAccounts();
          break;
        case 'transactions':
          this.getTransactions();
          break;
      }
    },
    handleCurrentChange(newPage, type) {
      this.filter.currentPage = newPage;
      switch (type) {
        case 'notes':
          this.getCustomerNotes();
          break;
        case 'docs':
          this.getCustomerDocuments();
          break;
        case 'accounts':
          this.getAccounts();
          break;
        case 'transactions':
          this.getTransactions();
          break;
      }
    },
    async getTransactions() {
      this.loading = false;
      try {
        let response = await customerService.getCustomer(this.customer.appUserId);
        this.customer = response?.data ?? this.customer;

      }
          // eslint-disable-next-line no-empty
      catch (e) {

      }
    },
    async getCustomer(customerId) {
      this.loading = true;
      try {
        let response = await customerService.getCustomer(customerId);
        this.customer = response?.data ?? this.customer;
        if (this.customer !== null) {
          this.filter.customerId = this.customer.id
        }
      }
          // eslint-disable-next-line no-empty
      catch (e) {

      }
      this.loading = false;
    },
    async addCustomer() {
      this.loading = true;
      try {
        let response = await customerService.add(this.customer);
        if (response?.data !== null) {
          ShowMessage("success", "Customer Added");
          this.$router.push('/customers')
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Add Customer Failed");
      }
      this.loading = false;
    }, async updateCustomer() {
      this.loading = true;
      try {
        let response = await customerService.update(this.customer);
        if (response?.data !== null) {
          ShowMessage("success", "Customer Updated");
          this.$router.push('/customers')
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Add Customer Failed");
      }
      this.loading = false;
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null || this.clientId === undefined) {
          id = 0;
        } else {
          id = this.clientId;
        }
        let response = await configService.getByKey(id, "CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey(id, "CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey(id, "DateFormat");
        this.currencyPosition = response3.data.value ?? "DD/MM/YYYY";

        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getClients() {
      try {
        this.loading = true;
        if (Number(sessionStorage.getItem('clientId')) === null || Number(sessionStorage.getItem('clientId')) === 0) {
          let response = await clientService.getAll();
          this.clients = response.data.items ?? [];
          if (this.clients.length > 0) {
            this.filter.clientGuid = this.clients[0].clientGuid;
            this.customer.clientId = this.clients[0].id;
            this.filter.clientId = this.clients[0].id;
          }
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async getCountries() {
      try {
        this.loading = true;
        let response = await optionService.getOptionsByHeaderId(0, 7)
        this.countries = response.data ?? {};
        if (this.countries.length > 0) {
          if (this.customer.county === "") {
            this.customer.county = this.countries[0].optTitle;
          }
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loading = false;
      }
    },

    async getCompanySize() {
      try {
        this.loading = true;
        let response = await optionService.getOptionsByHeaderId(0, 35)
        this.companySizes = response.data ?? {};
        if (this.companySizes.length > 0) {
          if (this.customer.companyType === "" || this.customer.companyType === 0) {
            this.customer.companyType = this.companySizes[0].optId;
          }
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loading = false;
      }
    },

    async getNotesCategories() {
      try {
        this.loading = true;
        let response = await noteService.getNotesCategories();
        this.notesCategories = response.data.items ?? [];
        if (this.notesCategories.length > 0) {
          this.note.noteCategoryId = this.notesCategories[0].id;
        } else {
          this.note.noteCategoryId = ""
        }
        this.loading = false;
      } catch (e) {
        ShowMessage("error", e?.msg ?? "Something went wrong!");

      }
      this.loading = false;
    },
    async addNote() {
      this.addNoteDialog = false;
      this.loading = true;
      try {
        let response = await customerNoteService.addNote(this.note);
        if (response?.data?.id > 0) {
          ShowMessage("success", "Note added");
          await this.getCustomerNotes();
        }
      }
          // eslint-disable-next-line no-empty
      catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async updateNote() {
      this.addNoteDialog = false;
      this.loading = true;
      try {
        let response = await customerNoteService.updateNote(this.note);
        if (response?.data?.data != null) {
          ShowMessage("success", "Note updated");
          await this.getCustomerNotes();
        }
      }
          // eslint-disable-next-line no-empty
      catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async deleteNote() {
      this.deleteNoteDialog = false;
      this.loading = true;
      try {
        let response = await customerNoteService.deleteNote(this.note.id);
        if (response?.data?.data != null) {
          ShowMessage("success", "Note deleted");
          await this.getCustomerNotes();
        }
      }
          // eslint-disable-next-line no-empty
      catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getCustomerNotes() {
      this.loading = true;
      try {
        let response = await customerNoteService.getNotes(this.filter);
        this.notes = response?.data?.items ?? [];

      }
          // eslint-disable-next-line no-empty
      catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getDocumentCategories() {
      this.loading = true;
      try {
        let response = await documentService.getDocumentsCategories(this.customer.clientId);
        this.documentCategories = response?.data ?? [];
        if (this.documentCategories.length > 0) {
          this.doc.categoryId = this.documentCategories[0].optId;
        }
      }
          // eslint-disable-next-line no-empty
      catch (e) {

      }
      this.loading = false;
    },
    async getCustomerDocuments() {
      this.loading = true;
      try {
        let response = await customerDocumentService.getDocs(this.filter);
        this.documents = response?.data?.items ?? [];

      }
          // eslint-disable-next-line no-empty
      catch (e) {

      }
    },
    async addDoc() {
      this.addDocDialog = false;
      this.loading = true;
      try {
        let response = await customerDocumentService.addDoc(this.doc);
        if (response?.data) {
          ShowMessage("success", "Document added");
          await this.getCustomerDocuments();
        }
      }
          // eslint-disable-next-line no-empty
      catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async updateDoc() {
      this.addDocDialog = false;
      this.loading = true;
      try {
        let response = await customerDocumentService.updateDoc(this.doc);
        if (response?.data) {
          ShowMessage("success", "Document updated");
          await this.getCustomerNotes();
        }
      }
          // eslint-disable-next-line no-empty
      catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async deleteDoc() {
      this.deleteDocDialog = false;
      this.loading = true;
      try {
        let response = await customerDocumentService.deleteDoc(this.doc.id)
        if (response?.data) {
          ShowMessage("success", "Document deleted");
          await this.getCustomerDocuments();
        }
      }
          // eslint-disable-next-line no-empty
      catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async addAccount() {
      this.addAccountDialog = false;
      this.loading = true;
      try {
        let response = await customerService.addAccount(this.account)
        if (response?.data) {
          ShowMessage("success", "Account added");
          await this.getAccounts();
        }
      }
          // eslint-disable-next-line no-empty
      catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getAccounts() {
      this.loading = true;
      try {
        let response = await customerService.getAccounts(this.filter);
        this.accounts = response?.data?.items ?? [];

      }
          // eslint-disable-next-line no-empty
      catch (e) {

      }
      this.loading = false;
    }
  },
  created() {
    this.translations = getLanguage();
    this.getCountries();
    if (this.$store.state.pageId > 0) {
      this.edit = false;
      this.getCustomer(this.$store.state.pageId)
    }
    this.getConfigs();
    this.getClients();
    this.getNotesCategories();
    this.getCompanySize();
  }
}
</script>

<style scoped>
fieldset {
  margin-bottom: 1em !important;
  border: 1px solid rgba(0, 0, 0, 0.175) !important;
  padding: 1px !important;
  border-radius: 5px;
}

legend {
  padding: 1px 5px !important;
  float: none;
  width: auto;
  margin: 0 5px;
}
</style>