import config from "@/Configuration/Config"
import axios from 'axios';

export default {
    getEmailsUrl: config.apiBaseUrl + "email/getemails?clientId=",
    getEmailUrl: config.apiBaseUrl + "email/getemailbyid?emailId=",
    addEmailUrl: config.apiBaseUrl + "email/add",
    updateEmailUrl: config.apiBaseUrl + "email/update",
    deleteEmailUrl: config.apiBaseUrl + "email/delete?emailId=",


    getEmails(filter) {
        return axios.get(this.getEmailsUrl+filter.clientId+"&propertyId=" + filter.propertyId + "&tenantId=" + filter.tenantId + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getEmail(id) {
        return axios.get(this.getEmailUrl + id);
    },
    addEmail(receipt) {
        return axios.post(this.addEmailUrl, receipt);
    },
    updateEmail(receipt) {
        return axios.put(this.updateEmailUrl, receipt);
    },
    deleteEmail(id) {
        return axios.delete(this.deleteEmailUrl + id);
    }


}