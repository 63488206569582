<template>
  <div class="container-fluid">
    <!--    <ToolBar :show-select="false" :ShowAddBtn="profileEdit" btn-text="Update" @addbtn-clicked="UpdateUser">
          <template v-slot:select>
            <el-button type="primary" @click="edit = !edit">Edit</el-button>
          </template>
        </ToolBar>-->
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-8">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">

          <el-button v-if="!edit" @click="edit = !edit" text bg class="btn btn-light">
            <i class="bi bi-chevron-left me-2"></i>
            Cancel
          </el-button>
          <el-button v-if="edit" text bg class="border-0 mbtn-primary ms-2" @click="edit = !edit">
            Edit
          </el-button>
          <el-button @click="UpdateUser" v-if="!edit" :disabled="edit"
                     class="border-0 mbtn-primary ms-2">Update
          </el-button>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-lg-5">
        <div class="card shadow-sm ">
          <div class="card-header">
            <div class="py-1">
              <span class="fs-5 mfw-bold">{{ User.displayName }}</span>
              <span v-if="User.isActive" class="bi bi-patch-check-fill text-success ms-2"></span>
              <span v-else class="bi bi-patch-exclamation-fill text-danger ms-2"></span>
            </div>
          </div>
          <div class="px-3 py-2">
            <div class="mt-2">

              <img v-if="User.image !== '' && User.image !== null" :src="User.image" alt="Avatar" width="60"/>
              <el-avatar v-else size="large" shape="square">
                <el-icon>
                  <i class="bi bi-person-fill fs-2"></i>
                </el-icon>
              </el-avatar>
            </div>
            <div class="mt-3">
              <div class="row">
                <div class="col-12 col-md-4 text-start">
                  <span class="mfw-bold text-caption">{{ getMessageByCode('username') ?? 'Username' }}</span>
                </div>
                <div class="col-12 col-md-8 text-end">
                  <span class="text-caption">{{ User.userName }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 col-md-3 text-start">
                  <span class="mfw-bold text-caption">{{ getMessageByCode('email') ?? 'Email' }}</span>
                </div>
                <div class="col-12 col-md-9 text-end">
                  <span class="text-caption">{{ User.email }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 col-md-3 text-start">
                  <span class="mfw-bold text-caption">{{ getMessageByCode('contact') ?? 'Contact' }}</span>
                </div>
                <div class="col-12 col-md-9 text-end">
                  <span class="text-caption">{{ User.mobile ?? "" }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 col-md-3 text-start">
                  <span class="mfw-bold text-caption">{{ getMessageByCode('status') ?? 'Status' }}</span>
                </div>
                <div class="col-12 col-md-9 text-end">
                  <el-button size="small" v-if="User.isActive" class="btn-s-secondary mfw-bold text-caption">
                    {{ getMessageByCode('active') ?? 'Active' }}
                  </el-button>
                  <el-button size="small" v-else class="btn-d-secondary mfw-bold text-caption">
                    {{ getMessageByCode('inactive') ?? 'InActive' }}
                  </el-button>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <el-menu class="border-0" default-active="1">
                    <el-menu-item class="tab-item rounded" index="1" @click="tabChange(1)">
                      <el-icon>
                        <i class="bi bi-person-check-fill"></i>
                      </el-icon>
                      <span class="text-capitalize">{{ getMessageByCode('pers_info') ?? 'Personal Info' }}</span>
                    </el-menu-item>
                    <el-menu-item class="tab-item rounded" index="2" @click="tabChange(2)">
                      <el-icon>
                        <i class="bi bi-shield-fill-exclamation"></i>
                      </el-icon>
                      <span class="text-capitalize">{{ getMessageByCode('chng_pass') ?? 'Change Password' }}</span>
                    </el-menu-item>
                    <el-menu-item class="tab-item rounded" index="3" @click="tabChange(3)">
                      <el-icon>
                        <i class="bi bi-gear-fill"></i>
                      </el-icon>
                      <span class="text-capitalize">{{ getMessageByCode('settings') ?? 'Settings' }}</span>
                    </el-menu-item>
                  </el-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7">
        <div class="card shadow-sm">
          <div class="card-header">
            <div class="py-1">
              <span class="fs-5 mfw-bold text-capitalize">{{
                  getMessageByCode('pers_info') ?? 'Personal Information'
                }}</span>
            </div>
          </div>
          <div class="py-4 px-3">
            <div v-if="activeTab === 1">
              <div class="row justify-content-center">
                <div class="d-flex justify-content-center">
                  <span
                      class="mfw-bold text-capitalize">{{
                      getMessageByCode('cusotmer_info') ?? 'Change Password'
                    }}</span>
                </div>
                <div class="col-12 col-lg-8 mt-3">
                  <el-form label-position="right" label-width="200px">
                    <el-form-item label="Avatar">
                      <div class="avatar-container">
                        <div class="u-btn" :class="{ 'disabled': edit }" @click="openFilePicker">
                          <i class="bi bi-pencil-square"></i>
                          <input ref="fileInput" accept="image/*" style="display: none" type="file" :key="fileInputKey"
                                 @change="handleFileChange"/>
                        </div>
                        <el-avatar v-if="User.image === null || User.image === ''" shape="square"
                                   style="width: 75px; height: 75px">
                          <template #default>
                            <i class="bi bi-person-fill display-4"></i>
                          </template>
                        </el-avatar>
                        <div v-else class="img-container">
                          <img :src="User.image" alt="Avatar" width="73"/>
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item class="text-capitalize" :label="getMessageByCode('username')??'Username'">
                      <el-input :disabled="edit" v-model="User.userName"></el-input>
                    </el-form-item>
                    <el-form-item class="text-capitalize" :label="getMessageByCode('disp_name')??'Display Name'">
                      <el-input :disabled="edit" v-model="User.displayName"></el-input>
                    </el-form-item>
                    <el-form-item class="text-capitalize" :label="getMessageByCode('email')??'Email'">
                      <el-input :disabled="edit" v-model="User.email">
                        <template #prepend>
                          <el-button>
                            <el-icon>
                              <i class="bi bi-envelope-at"></i>
                            </el-icon>
                          </el-button>
                        </template>
                      </el-input>
                    </el-form-item>
                    <el-form-item class="text-capitalize" :label="getMessageByCode('cont_num')??'Contact Number'">
                      <el-input :disabled="edit" v-model="User.mobile">
                        <template #prepend>
                          <el-button>
                            <el-icon>
                              <i class="bi bi-telephone"></i>
                            </el-icon>
                          </el-button>
                        </template>
                      </el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <div v-if="activeTab === 2">
              <div class="row justify-content-center">
                <div class="d-flex justify-content-center">
                  <span class="mfw-bold">Change or recover your password here.</span>
                </div>
                <div class="col-12 col-md-7 mt-5">
                  <el-form label-position="right" label-width="150px">
                    <el-form-item label="Password">
                      <el-input :disabled="edit"></el-input>
                    </el-form-item>
                    <el-form-item label="Confirm Password">
                      <el-input :disabled="edit"></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <div v-if="activeTab === 3">
              <div class="row justify-content-center">
                <div class="d-flex justify-content-center">
                  <span class="mfw-bold">Set email notification here.</span>
                </div>
                <div class="col-12  mt-5">
                  <el-form label-position="right" label-width="200px">
                    <el-form-item :label="getMessageByCode('emailNotification') ?? 'Email Notification'">
                      <el-switch :disabled="edit" v-model="User.isEmailNotification"></el-switch>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('emailSignature') ?? 'Email Signature'">
                      <el-input v-if="!User.signature || !edit" :disabled="edit"
                                placeholder="Paste your html signature code here..." :rows="5" type="textarea"
                                v-model="User.signature"></el-input>
                      <div class="p-2 signatureDiv" v-else v-html="User.signature"></div>
                    </el-form-item>

                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading" class="spinner-border d-loader"></div>
</template>

<script>
import {UserFilled} from "@element-plus/icons-vue";
import userService from "@/Services/ComponentsServices/userService";
import {ShowMessage} from "@/Utility/Utility";
import {checkPermission} from "@/Utility/CheckPermission";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "UserProfile",
  components: {BreadCrumb},
  data() {
    return {
      activeTab: 1,
      loading: false,
      profileEdit: false,
      profileView: false,
      edit: true,
      cardTitle: "Personal Information",
      UserFilled: UserFilled,
      imageUrl: null,
      translations: [],
      fileInputKey: Date.now(),
      User: {
        "id": sessionStorage.getItem("mUserId"),
        "clientId": sessionStorage.getItem("clientId"),
        "userName": "",
        "email": "",
        "displayName": "",
        "isActive": false,
        "mobile": null,
        "image": "",
        "isEmailNotification": false,
        "permissionList": [],
        "signature": "",
        "password": ""
      }
    }
  },
  methods: {
    tabChange(tab) {
      this.activeTab = tab;
      switch (tab) {
        case 1:
          this.cardTitle = "Personal Information";
          break;
        case 2:
          this.cardTitle = "Change Password";
          break;
        case 3:
          this.cardTitle = "Email Notification";
          break;
      }
    },
    openFilePicker() {
      this.fileInputKey = Date.now();
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      if (this.edit) return;
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.User.image = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async getUser() {
      try {
        this.loading = true;
        let response = await userService.getbyId(this.User.id);
        this.User = response.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
    async UpdateUser() {
      try {
        this.loading = true;

        let response = await userService.update(this.User);
        if (response.status === 200) {
          await this.getUser();
          this.$store.state.refreshProfile = true;
          ShowMessage("success", "Profile updated successfully");
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    }
    ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.profileEdit = checkPermission("MD_USER_PROFILE_EDIT");
    this.profileView = checkPermission("MD_USER_PROFILE_VIEW");
    this.getUser();
  }
}
</script>

<style scoped>
.el-menu-item.is-active {
  background-color: #F2F3F7 !important;
  font-weight: 600 !important;
  color: #0d6efd !important;
}

.tab-item:hover {
  background-color: #F2F3F7 !important;
  /*font-weight: 600 !important;*/
  /*color: #0d6efd !important;*/
}

.tab-item {
  height: 45px !important;
}

.avatar-container {
  position: relative !important;
}

.u-btn {
  position: absolute;
  font-size: 13px;
  top: -10px;
  right: -5px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container {
  box-shadow: 0 0 0 1px grey inset;
  width: 75px;
  height: 75px;
  border-radius: 5px;
  object-fit: fill;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  pointer-events: none;
  opacity: 0.5; /* Optionally reduce opacity to indicate it's disabled */
  /* Add any other styles to visually indicate it's disabled */
}

.signatureDiv {
  font-size: 12px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  max-height: 300px
}
</style>