
import axios from "axios"
import Config from "@/Configuration/Config";
export default {
    allUrl: Config.apiBaseUrl + "package/getpackages?marketId=",
    getAllUrl: Config.apiBaseUrl + "package/getpackages?marketId=",
    getByIdUrl: Config.apiBaseUrl + "package/getpackagebyid?marketId=",
    addUrl: Config.apiBaseUrl + "package/add",
    updateUrl: Config.apiBaseUrl+"package/update",
    getPkgModulesUrl: Config.apiBaseUrl+"package/getmodules?packageId=",
    deleteUrl: Config.apiBaseUrl+"package/delete?packageId=",

    updateModuleUrl: Config.apiBaseUrl +"package/updatemodule",
    addModuleUrl: Config.apiBaseUrl +"package/addmodule",
    deleteModuleUrl: Config.apiBaseUrl +"package/deleteModule?moduleId=",

    all(filter) {
        return axios.get(this.allUrl+filter.marketId+"&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll(id) {
        return axios.get(this.getAllUrl+id+"&limit=1500");
    },
    getById(id) {
        return axios.get(this.getByIdUrl + id);
    },
    add(pacakge) {
        return axios.post(this.addUrl, pacakge);
    },
    update(pacakge) {
        return axios.put(this.updateUrl, pacakge);
    },
    getModules(packageId) {
        return axios.get(this.getPkgModulesUrl+ packageId);
    },
    delete(packageId) {
        return axios.delete(this.deleteUrl+packageId);
    },
    updateModule(module){
        return axios.put(this.updateModuleUrl,module);
    },
    addModule(module){
        return axios.post(this.addModuleUrl,module);
    },
    deleteModule(id){
        return axios.delete(this.deleteModuleUrl+id);
    },

}