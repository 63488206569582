<template>
  <div class="container-fluid p-2 p-md-0 main-wrapper" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">

    <div class="row login-wrapper  justify-content-center align-items-center">
      <div class="col-12 col-md-4 bg-white py-5 shadow  rounded text-center">
        <h3 class="fw-bold">Create your free account</h3>
        <h6 class="text-secondary">100% free. No credit card needed.</h6>
        <div class="d-flex flex-column align-items-center justify-content-center py-3">
          <el-button bg class="btn btn-light w-100 w-md-75 signup-btn" size="large" text>
            <img
                alt=""
                src="../../assets/svg/brand-logos/google-icon.svg"
                width="18"
            />
            <span class="ms-2">Continue with Google</span>
          </el-button>
          <el-button bg class="btn btn-light w-100 w-md-75 m-0 mt-2 signup-btn" size="large" text>
            <img
                alt=""
                src="../../assets/svg/brand-logos/microsoft-5.svg"
                width="18"
            />
            <span class="ms-2">Signup with Microsoft</span>
          </el-button>
          <el-divider class="w-100 w-md-50">OR</el-divider>
          <!--               <el-form :model="User" :rules="formRules" class="w-100 text-center d-flex justify-content-center">

                          <el-form-item prop="email" :rules="formRules.emailRule" class="w-100 text-center d-flex justify-content-center">
                              <el-input  v-model="User.email" class="w-100 w-md-50" placeholder="Email address" size="large"></el-input>
                            </el-form-item>
                         </el-form>-->
          <el-input v-if="!emailSent" v-model="User.email" class="w-100 w-md-75" placeholder="Email address" size="large"></el-input>
          <span v-else>Please open you email and click the link to verify your email account. You will be then continue to register with us.</span>
          <el-button v-if="!emailSent" @click="verifyEmail()" :loading="loading" :disabled="!User.email" class="w-100 w-md-75 mt-5"
                     type="primary" size="large">
            <span>Verify Email</span>
            <i class="bi bi-chevron-right ms-3"></i>

          </el-button>
          <el-button plain @click="goToLogin()" class="w-100 w-md-75 mt-2 m-0"
                     size="large">

            <i class="bi bi-chevron-left me-3"></i>
            <span>Go to login</span>
          </el-button>
        </div>

      </div>
    </div>
    <div v-if="loading" class="spinner-border signup-loader"></div>
  </div>
</template>
<script>
import userService from "@/Services/ComponentsServices/userService";
import {ShowMessage} from "@/Utility/Utility";
import loginCoreService from "@/Services/ComponentsServices/loginCoreService";

export default {
  name: "SignupPage",
  data() {
    return {
      loading: false,
      validated: false,
      emailSent: false,
      imageUrl: "",
      User: {
        email: "",
      },
      formRules: {
        nameRule: [
          {required: true, message: 'Please enter your name', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        emailRule: [
          {required: true, message: 'Please enter the email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ],
      },
    }

  },
  methods: {
    validateEmail(rule, value, callback) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        this.validated = true;
        callback();
      } else {
        this.validated = false;
        callback(new Error('Please enter a valid email address'));
      }
    },
    goToLogin() {
      this.$router.push('/')
    },
    async verifyEmail() {
      try {
        this.loading = true;
        let response = await userService.signup(this.User.email);
        if (response.data.success) {
          this.emailSent = true;
          ShowMessage("success", "Verification link sent to your email. Please click the link to verify!")
        } else {
          this.emailSent = false;
          ShowMessage("error", "Email already registered!")
        }
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    }
  },
  created() {
    loginCoreService.getLoginImage("[LoginBackground]").then((res) => {
      this.imageUrl = res.data.imageData;
    });
  }
}
</script>

<style scoped>
.main-wrapper {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-wrapper {
  height: 100vh;
  overflow: hidden !important;
}

.login-loader {
  position: absolute;
  z-index: 999;
  left: 23%;
  top: 35%;
}

.el-form {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
}

.el-form-item__content {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
}

.signup-loader {
  position: absolute;
  z-index: 999;
  left: 49.2%;
  top: 50.2%;
}

</style>