import Config from "@/Configuration/Config";
import axios from "axios";

export default {
    allUrl: Config.apiBaseUrl + "pmsproperty/getproperties?clientId=",
    getAllUrl: Config.apiBaseUrl + "pmsproperty/getallproperties?clientId=",
    getUrl: Config.apiBaseUrl + "pmsproperty/getpropertybyid?propertyId=",
    addUrl: Config.apiBaseUrl + "pmsproperty/add",
    updateUrl: Config.apiBaseUrl + "pmsproperty/update",
    deleteUrl: Config.apiBaseUrl + "pmsproperty/delete?propertyId=",
    freePropertiesUrl: Config.apiBaseUrl + "pmsproperty/getfreeproperties?clientId=",
    // PmsDocumentsUrls
    allDocumentsUrl: Config.apiBaseUrl + "pmsproperty/getdocs?propertyId=",
    getDocByIdUrl: Config.apiBaseUrl + "pmsproperty/getdocbyid?docId=",
    addDocUrl: Config.apiBaseUrl + "pmsproperty/adddocument",
    updateDocUrl: Config.apiBaseUrl + "pmsproperty/updatedocument",
    deleteDocUrl: Config.apiBaseUrl + "pmsproperty/deletedoc?documentId=",
    // PmsNotesUrls
    allNotesUrl: Config.apiBaseUrl + "pmsproperty/getnotes?propertyId=",
    getNoteByIdUrl: Config.apiBaseUrl + "pmsproperty/getnotebyid?noteId=",
    addNoteUrl: Config.apiBaseUrl + "pmsproperty/addnote",
    updateNoteUrl: Config.apiBaseUrl + "pmsproperty/updatenote",
    deleteNoteUrl: Config.apiBaseUrl + "pmsproperty/deletenote?noteId=",

    //Dashboard Notes of Property
    getPmsDashboardNotesUrl : Config.apiBaseUrl+"pmsproperty/getdashboardnotes?clientId=",


    getProperties(filter) {
        return axios.get(this.allUrl + filter.clientId + "&category="+filter.category+ "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAllProperties(clientId) {
        return axios.get(this.getAllUrl + clientId);
    },
    getAll(filter) {
        return axios.get(this.allUrl + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getProperty(id) {
        return axios.get(this.getUrl + id);
    },
    addProperty(property) {
        return axios.post(this.addUrl, property)
    },
    updateProperty(property) {
        return axios.put(this.updateUrl, property)
    },
    deleteProperty(propertyId) {
        return axios.delete(this.deleteUrl + propertyId)
    },
    getFreeProperties(clientId) {
        return axios.get(this.freePropertiesUrl + clientId)
    },
    //PMS Documents Methods
    getDocuments(filter) {
        return axios.get(this.allDocumentsUrl + filter.propertyId + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getDocument(id) {
        return axios.get(this.getDocByIdUrl + id);
    },
    addDocument(document) {
        return axios.post(this.addDocUrl, document)
    },
    updateDocument(document) {
        return axios.put(this.updateDocUrl, document)
    },
    deleteDocument(docId) {
        return axios.delete(this.deleteDocUrl + docId)
    },
    //PMS Notes Methods
    getNotes(filter) {
        return axios.get(this.allNotesUrl + filter.propertyId + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getNote(id) {
        return axios.get(this.getNoteByIdUrl + id);
    },
    addNote(note) {
        return axios.post(this.addNoteUrl, note)
    },
    updateNote(note) {
        return axios.put(this.updateNoteUrl, note)
    },
    deleteNote(noteId) {
        return axios.delete(this.deleteNoteUrl + noteId)
    },
    getDashboardNotes(filter){
        return axios.get(this.getPmsDashboardNotesUrl+filter.clientId+"&page="+filter.currentPage+"&limit="+filter.pageSize);
    }

}