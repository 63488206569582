import Config from "@/Configuration/Config";
import axios from 'axios';

export default {
    allUrl: Config.apiBaseUrl + "pmstenant/gettenants?clientId=",
    getContactTenantsUrl: Config.apiBaseUrl + "pmstenant/getcontacttenants?contactId=",
    getUrl: Config.apiBaseUrl + "pmstenant/gettenantbyid?tenantId=",
    addUrl: Config.apiBaseUrl + "pmstenant/add",
    updateUrl: Config.apiBaseUrl + "pmstenant/update",
    deleteUrl: Config.apiBaseUrl + "pmstenant/delete?tenantId",
    forceDeleteUrl: Config.apiBaseUrl + "pmstenant/forcedelete?tenantId",
    getContactAllTenantsUrl: Config.apiBaseUrl + "pmstenant/getcontactalltenants?clientId=",

    all(filter) {
        return axios.get(this.allUrl + filter.clientId + "&search=" + filter.search + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getContactTenants(filter) {
        return axios.get(this.getContactTenantsUrl + filter.contactId + "&search=" + filter.search + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getTenantById(id) {
        return axios.get(this.getUrl + id);
    },
    addTenant(tenant) {
        return axios.post(this.addUrl, tenant);
    },
    updateTenant(tenant) {
        return axios.put(this.updateUrl, tenant);
    },
    deleteTenant(tenantId) {
        return axios.delete(this.deleteUrl + tenantId);
    },
    forceDeleteTenant(tenantId) {
        return axios.delete(this.forceDeleteUrl + tenantId);
    },
    getContactAllTenants(clientId) {

        return axios.get(this.getContactAllTenantsUrl + clientId);
    }

}