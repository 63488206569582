<!--suppress JSValidateTypes -->
<template>
  <div class="container-fluid">
    <ToolBar :show-bread="false" :options="SelectOptions" clear-id="tenantId" @selection-changed="getTenants"
             :showSelect="true" :ShowAddBtn="false"
             routeName="contact" :btnText="getMessageByCode('add_tnnt')??'Add Tenant'">
      <template v-slot:select>
        <el-select v-if="clientId == null || Number(clientId) == 0" class="w-100" filterable @change="ChangeClient"
                   v-model="filter.clientId">
          <el-option
              v-for="item in Clients"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </template>
    </ToolBar>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="d-flex justify-content-end">
            <el-button v-if="selectedIds.length > 1"
                       class="bg-danger text-white fw-normal me-2 border-0"
                       @click="deleteTenantDialog = !deleteTenantDialog">
              <i class="bi bi-trash me-2"></i>
              {{ selectedIds.length }} {{ getMessageByCode('selected') ?? 'selected' }}
            </el-button>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mb-2 ms-auto d-flex justify-content-end">
          <el-input class="align-self-end" :placeholder="getMessageByCode('search') ?? 'Search here...'"
                    @clear="getTenants" v-model="filter.search"
                    @keyup="getTenants"
                    clearable>
            <template #append>
              <el-button @click="getTenants">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <el-table :data="Tenants" show-overflow-tooltip :default-sort="{ prop: 'firstName', order: 'ascending' }"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe @selection-change="handleSelectionChange">
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <!--            <el-table-column align="center" type="selection" width="55"/>-->
            <el-table-column show-overflow-tooltip sortable :label="getMessageByCode('first_name') ??'First Name'" prop="firstName" >
              <template #default="scope">
            <span class="router-text" @click="editTenant(scope.row, 'edit')">{{
                scope.row.firstName
              }}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip sortable :label="getMessageByCode('surname') ??'Surname'" width="auto" prop="surname"/>
            <el-table-column show-overflow-tooltip sortable :label="getMessageByCode('mobile') ??'Mobile'" width="auto" prop="mobile"/>
            <el-table-column show-overflow-tooltip sortable :label="getMessageByCode('email') ??'Email'" width="auto" prop="email"/>
            <el-table-column show-overflow-tooltip sortable min-width="250" :label="getMessageByCode('address')??'Address'" prop="address1">
              <template #default="scope">
                <span>{{ scope.row.address1 }}</span><span v-if="scope.row.address2">, {{
                  scope.row.address2
                }}</span><span v-if="scope.row.address3">, {{ scope.row.address3 }}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip sortable :label="getMessageByCode('city') ??'City'" resizable width="auto" prop="city"/>
            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions') ??'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <i
                      class="mgc_edit_line text-primary action-btn"
                      @click="editTenant(scope.row, 'edit')"
                  ></i>
                  <i v-show="tenantDel" @click="editTenant(scope.row, 'del')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border"></div>
    <el-dialog
        v-model="deleteTenantDialog"
        width="30%"
    >
      <template #header>
        <span>{{ getMessageByCode('delete') ?? 'Delete' }} {{ getMessageByCode('tenant') ?? 'Tenant' }}</span>
      </template>
      <span>{{ getMessageByCode('do_u_del') ?? 'Do you want to delete' }} {{
          tenant?.firstName
        }}</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deleteTenantDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteTenantDialog = false, deleteTenant()">
          {{ getMessageByCode('delete') ?? 'Delete' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog v-model="addDialog" width="650" :title="tenant.id > 0 ? 'Edit Contact' : 'Add Contact'" draggable
               align-center>
      <template #default>
        <el-form label-position="top">
          <div class="row">
            <el-form-item class="col-12" v-if="clientId === null || clientId === 0 || !clientId">
              <template #label>
                <span class="required">{{ getMessageByCode('client') ?? 'Client' }}</span>
              </template>
              <el-select v-model="tenant.clientId" filterable class="w-100"
                         placeholder="Select"
              >
                <el-option
                    v-for="item in clients"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-12 col-md-6">
              <template #label>
                <span class="required">{{ getMessageByCode('category') ?? 'Category' }}</span>
              </template>
              <n-button-group class="w-100">
                <el-select disabled v-model="tenant.categoryId" filterable class="w-100"
                           placeholder="Select Category"
                >
                  <el-option
                      v-for="item in categories"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                      :disabled="Number(item.id)=== 0"
                  >
                  </el-option>
                </el-select>
                <el-button disabled type="primary" >+</el-button>
              </n-button-group>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('title') ?? 'Title'">
              <el-select v-model="tenant.title" class="w-100">
                <el-option v-for="type in honorifics"
                           :key="type.optId"
                           :label="type.optTitle"
                           :value="type.optTitle"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('firstName') ?? 'First Name'">
              <el-input v-model="tenant.firstName"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('surname') ?? 'Surname'">
              <el-input v-model="tenant.surname"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('email') ?? 'Email'">
              <el-input v-model="tenant.email"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('fax') ?? 'Fax'">
              <el-input v-model="tenant.fax"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('phone') ?? 'Telephone'">
              <el-input v-model="tenant.telephone"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('mobile') ?? 'Mobile'">
              <el-input v-model="tenant.mobile"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('address') ?? 'Address ' + '1'">
              <el-input v-model="tenant.address1"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('address') ?? 'Address ' + '2'">
              <el-input v-model="tenant.address2"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('address') ?? 'Address ' + '3'">
              <el-input v-model="tenant.address3"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('postcode') ?? 'Postcode'">
              <el-input v-model="tenant.postCode"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('city') ?? 'City'">
              <el-input v-model="tenant.city"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('region') ?? 'Region'">
              <el-input v-model="tenant.region"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button type="primary" v-if="tenant.id < 1" @click="addContact()">
            {{ getMessageByCode('addContact') ?? 'Add Contact' }}
          </el-button>
          <el-button type="primary" v-else @click="update()">{{ getMessageByCode('updateContact') ?? 'Update Contact' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import FilterService from "@/components/Services/FilterService";
import tenantService from "@/Services/ComponentsServices/pmsTenantService";
import clientService from "@/Services/ComponentsServices/clientService";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";
import {ShowMessage} from "@/Utility/Utility";
import contactService from "@/Services/ComponentsServices/contactService";
import {NButtonGroup} from 'naive-ui'
import optionService from "@/Services/ComponentsServices/optionService";
export default {
  name: "PmsTenantList",
  components: {ToolBar,NButtonGroup},
  data() {
    return {
      tenantId: 0,
      addDialog: false,
      clientId: sessionStorage.getItem("clientId"),
      translations: [],
      deleteTenantDialog: false,
      tenantCreate: false,
      tenantDel: false,
      Tenants: [],
      Clients: [],
      selectedIds: [],
      categories:[],
      loading: false,
      honorifics: [],
      tenant: {},
      SelectOptions: [{label: "All", value: 1}, {label: "Active", value: 2}, {label: "InActive", value: 3}],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        contactId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        category: 1,
      },
    }
  },
  methods: {
    ChangeClient() {
      this.getTenants();
    },
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },
    editTenant(tenant, type) {
      this.tenant = JSON.parse(JSON.stringify(tenant));
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'del':
          this.deleteTenantDialog = true;
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getTenants();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getTenants();
    },

    async getTenants() {
      try {
        this.loading = true;
        let response = await contactService.all(this.filter)
        this.Tenants = response.data.items ?? [];
        console.log(this.Tenants)
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? 1;
        this.filter.pageSize = response.data.pageSize;

      } catch (e) {
        console.log(e);

      }
      this.loading = false;
    },
    async deleteTenant() {
      this.loading = true;
      try {
        let response = await tenantService.deleteTenant(this.tenant.id)
        if (response.status === 200)
          ShowMessage("success", this.tenant.firstName + " Deleted Successfully");
        await this.getTenants();
      } catch (e) {
        console.log(e);
        ShowMessage("error", e?.response?.data?.message ?? "Something went wrong!");
      }
      this.loading = false;
    },
    async getClients() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          this.loading = true;
          let response = await clientService.getAll();
          this.Clients = response.data.items ?? [];
          if (this.Clients.length > 0) {
            this.filter.clientId = this.Clients[0].id;
          }
        }
        await this.getTenants();
      } catch (e) {
        console.log(e);

      }
      this.loading = false;
    }
    ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getCategories(id) {
      this.loading = true;
      try {
        let response = await contactService.getAllCategories();
        this.categories = [
          {id: 0, title: 'All'}
        ]
        this.categories = this.categories.concat(response?.data ?? [])
        if (this.categories.length > 0) {
          if (id !== undefined) {
            this.tenant.categoryId = id;
          } else {
            this.tenant.categoryId = this.categories[0].id;
          }
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
      await this.getContacts();
    },
    async getListOptions() {
      try {
        this.loading = true;
        let response = await optionService.getOptionsByHeaderId(0, 43)
        this.honorifics = response.data ?? {};
        if (this.honorifics.length > 0) {
          if (this.tenant.title === "") {
            this.tenant.title = this.honorifics[0].optTitle;
          }
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loading = false;
      }
    },
  },
  created() {
    this.translations = getLanguage();
    this.tenantCreate = checkPermission("PMS_TENANT_CREATE");
    this.tenantDel = checkPermission("PMS_TENANT_DEL");
    FilterService.resetFilter();
    this.getClients();
    this.getCategories();
    this.getListOptions();
  }
}
</script>

<style scoped>

</style>