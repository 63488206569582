<template>
  <div class="container-fluid">
    <ToolBar :ShowAddBtn="false" :show-bread="false" :show-select="false"/>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <router-link to="pmsproperties" class="text-decoration-none">
          <div class="card p-3 py-4 shadow-sm">
            <div class="d-flex justify-content-between align-items-center">
              <h3> {{ getMessageByCode('properties') ?? 'Properties' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-building-fill"></i>
              </div>
            </div>
            <div class="mt-4">
              <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('properties') ?? 'Properties' }}</span>
                  <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                      dashboard.totalProperty !== null && dashboard.totalProperty !== '' ? dashboard.totalProperty : "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('assigned') ?? 'Assigned' }}</span>
                  <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      dashboard.assignedProperty !== null && dashboard.assignedProperty !== '' ? dashboard.assignedProperty : "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('percentage') ?? 'Percentage' }}</span>
                  <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      dashboard.occupancyProperty !== null && dashboard.occupancyProperty !== '' ? dashboard.occupancyProperty : "0"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-md-12 mt-3 mt-lg-0 col-lg-4">
        <router-link to="pmstenants" class="text-decoration-none">
          <div class="card p-3 py-4 shadow-sm">
            <div class="d-flex justify-content-between align-items-center">
              <h3>{{ getMessageByCode('tenants') ?? 'Tenants' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-person-fill"></i>
              </div>
            </div>
            <div class="mt-4">
              <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('total_tenants') ?? 'Total Tenants' }}</span>
                  <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                      dashboard.totalTenant !== null && dashboard.totalTenant !== '' ? dashboard.totalTenant : "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100 h-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('assigned') ?? 'Assigned' }}</span>
                  <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      dashboard.assignedTenant !== null && dashboard.assignedTenant !== '' ? dashboard.assignedTenant : "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100 h-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('percentage') ?? 'Percentage' }}</span>
                  <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      dashboard.occupancyTenant !== null && dashboard.occupancyTenant !== '' ? dashboard.occupancyTenant : "0"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-md-12 mt-3 mt-lg-0 col-lg-4">
        <router-link to="contacts" class="text-decoration-none">
          <div class="card p-3 py-4 shadow-sm h-100">
            <div class="d-flex justify-content-between align-items-center">
              <h3>{{ getMessageByCode('contacts') ?? 'Contacts' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-person-fill"></i>
              </div>
            </div>
            <div class="mt-4">
              <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('totalContacts') ?? 'Total Contacts' }}</span>
                  <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                      dashboard.totalContacts !== null && dashboard.totalContacts !== '' ? dashboard.totalContacts : "0"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <el-table :data="notes" class="mt-3"
              :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
              header-cell-class-name="tbl-header"
              stripe>
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            size="small"
        >
        </el-pagination>
      </template>
      <el-table-column sortable :label="getMessageByCode('dateTime')??'Date Time'" width="170" prop="addedDate">
        <template #default="scope">
          <span>{{ getFormattedCheckedDate(scope.row.addedDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column  show-overflow-tooltip  :label="getMessageByCode('note')??'Note'" prop="note" sortable/>
      <el-table-column show-overflow-tooltip sortable  :label="getMessageByCode('property')??'Property'" width="250" prop="property">
        <template #default="scope">
          <span class="text-primary text-decoration-underline action-btn" style="font-size: 13px !important;" @click="goToProperty(scope.row.propertyId)">{{ scope.row.property }}</span>
        </template>
      </el-table-column>
      <el-table-column sortable :label="getMessageByCode('reminderDate')??'Reminder Date'" width="200" prop="reminderDate">
        <template #default="scope">
          <span>{{ getFormattedDate(scope.row.reminderDate) }}</span>
        </template>
      </el-table-column>


      <el-table-column sortable :label="getMessageByCode('checkedDate')??'Checked Date'" width="200" prop="checkedDate">
        <template #default="scope">
          <el-switch v-if="scope.row.requireCheck && !scope.row.isChecked" v-model="scope.row.isChecked"
                     @change="markChecked(scope.row)"></el-switch>
          <span v-else> {{ getFormattedCheckedDate(scope.row.checkedDate) }}</span>
        </template>
      </el-table-column>

    </el-table>

    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getMessageByCode} from "@/Utility/getMessagebyCode";
import dashboardService from "@/Services/ComponentsServices/dashboardService";
import pmsPropertiesService from "@/Services/ComponentsServices/pmsPropertiesService";
import store from "@/store";
import moment from "moment";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "PmsDashboard",
  components: {ToolBar},
  data() {
    return {
      loading: false,
      addNoteDialog: false,
      notes: [],
      dateFormat: "DD/MM/YYYY",
      clientId: sessionStorage.getItem('clientId') ?? 0,
      dashboard: {
        "totalProperty": "",
        "assignedProperty": "",
        "occupancyProperty": "",
        "totalTenant": "",
        "assignedTenant": "",
        "occupancyTenant": "",
        "totalContacts": "",
        "totalRoom": "",
        "assignedRoom": "",
        "occupancyRoom": "",
        "missingDoc": "",
        "expiredDoc": "",
        "expiredDoc7": "",
        "expiredDoc28": ""
      },
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        tenantId: 0,
        propertyId: store.state.pageId,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {

    goToProperty(id) {
      id = parseInt(id);
      if (id > 0) {
        this.$store.state.pageId = id;
        this.$router.push('/addpmsproperty');
      } else {
        ShowMessage('error', 'Property does not exists');
      }
    },
    markChecked(note) {
      note.isChecked = true;
      note.checkedDate = new Date();
      this.updatePropertyNote(note);
    },
    getFormattedDate(date) {
      if (date === null || date === undefined) {
        return "";
      }
      return moment.utc(date).local().format(this.dateFormat);
    },
    getFormattedCheckedDate(date) {
      if (date === null || date === undefined) return "";
      const formatWithTime = `${this.dateFormat} hh:mm A`;
      return moment.utc(date).local().format(formatWithTime);
    },
    getFormattedTime(date) {
      return moment.utc(date).local().format("LT");
    },
    getMessageByCode,
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getNotes();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getNotes();
    },
    editNote(note, type) {
      this.note = JSON.parse(JSON.stringify(note));
      switch (type) {
        case 'edit':
          this.addNoteDialog = true;
          break;
      }
    },
    async getNotes() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.getDashboardNotes(this.filter);
        this.notes = response?.data?.items ?? [];
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async getDashboardData() {
      try {
        this.loading = true;
        let response = await dashboardService.getPmsData(this.clientId);
        this.dashboard = response.data;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async updatePropertyNote(note) {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.updateNote(note);
        if (response?.data) {
          ShowMessage("success", "Note Updated");
          await this.getNotes();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
  },
  created() {
    this.getDashboardData();
    this.getNotes();
  }
}
</script>
<style scoped>

</style>