import axios from "axios";
import config from "@/Configuration/Config";

export default {
    getContactsUrl: config.apiBaseUrl + "clientcontact/getclientcontacts?marketId=",
    getAppClientsUrl: config.apiBaseUrl + "clientcontact/getappclients?postCode=",
    getByIdUrl: config.apiBaseUrl + "clientcontact/getclientcontactbyid?clientcontactId=",
    addUrl: config.apiBaseUrl + "clientcontact/add",
    updateUrl: config.apiBaseUrl + "clientcontact/update",
    softDeleteContactUrl: config.apiBaseUrl + "clientcontact/delete?contactId=",
    forceDeleteContactUrl: config.apiBaseUrl + "clientcontact/forcedelete?contactId=",

    //Types
    getTypesUrl: config.apiBaseUrl + "clientcontact/gettypes?search=",
    getAllTypesUrl: config.apiBaseUrl + "clientcontact/getalltypes",
    addTypeUrl: config.apiBaseUrl + "clientcontact/addtype",
    updateTypeUrl: config.apiBaseUrl + "clientcontact/updatetype",
    deleteTypeUrl: config.apiBaseUrl + "clientcontact/deletetype?typeId=",


    getContacts(filter) {
        return axios.get(this.getContactsUrl + filter.marketId + "&status=" + filter.status + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getAppClients(filter) {
        return axios.get(this.getAppClientsUrl + filter.postCode + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getContactById(contactId) {
        return axios.get(this.getByIdUrl + contactId);
    },
    addContact(contact) {
        return axios.post(this.addUrl, contact);
    },
    updateContact(contact) {
        return axios.put(this.updateUrl, contact);
    },
    softDelete(contactId) {
        return axios.delete(this.softDeleteContactUrl + contactId);
    },
    hardDelete(contactId) {
        return axios.delete(this.forceDeleteContactUrl + contactId);
    },
    //Types
    getTypes(filter) {
        return axios.get(this.getAllTypesUrl + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getAllTypes() {
        return axios.get(this.getAllTypesUrl);
    },
    addType(type) {
        return axios.post(this.addTypeUrl, type);
    },
    updateType(type) {
        return axios.put(this.updateTypeUrl, type);
    },
    deleteType(typeId) {
        return axios.delete(this.deleteTypeUrl + typeId);
    }

}