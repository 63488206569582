import config from "@/Configuration/Config"
import axios from 'axios';

export default {
    getPagesUrl: config.apiBaseUrl + "staticpage/getpages?clientId=",
    getPageUrl: config.apiBaseUrl + "staticpage/getpagebyid?pageId=",
    addPageUrl: config.apiBaseUrl + "staticpage/add",
    updatePageUrl: config.apiBaseUrl + "staticpage/update",
    deletePageUrl: config.apiBaseUrl + "staticpage/delete?pageId=",







    getPages(filter) {
        return axios.get(this.getPagesUrl+ filter.clientId+"&search="+ filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getPage(id) {
        return axios.get(this.getPageUrl + id);
    },
    addPage(faq) {
        return axios.post(this.addPageUrl, faq
        );
    },
    updatePage(faq) {
        return axios.put(this.updatePageUrl, faq);
    },
    deletePage(id) {
        return axios.delete(this.deletePageUrl + id);
    }


}