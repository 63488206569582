import Config from "@/Configuration/Config";
import axios from "axios"

export default {
    allurl: Config.apiBaseUrl + "tenant/gettenants?clientId=",
    allProviderTenantsurl: Config.apiBaseUrl + "tenant/getprovidertenants?providerId=",
    getallurl: Config.apiBaseUrl + "document/gettenants?limit=1500",
    assignedurl: Config.apiBaseUrl + "tenant/gettenants?tenantType=1&clientId=",
    geturl: Config.apiBaseUrl + "tenant/gettenantbyid?tenantId=",
    getTenantUrl: Config.apiBaseUrl + "tenant/getTenantbytenantid?tenantId=",
    //getbyclienturl: Config.apiBaseUrl + "room/getdocumentIdbyclient?clientId=",
    addurl: Config.apiBaseUrl + "tenant/add",
    updateurl: Config.apiBaseUrl + "tenant/update",
    deleteurl: Config.apiBaseUrl + "tenant/delete?tenantId=",
    getpropertydocsurl: Config.apiBaseUrl + "tenant/getpropertydocuments?proId=",
    getPmsTenantsUrl: Config.apiBaseUrl + "tenant/getpmstenants?tenantType=",
    all(filter) {
        return axios.get(this.allurl + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAllProviderTenants(filter) {
        return axios.get(this.allProviderTenantsurl + filter.providerId + "&clientId=" + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll(proId) {
        return axios.get(this.getallurl + "&proId=" + proId);
    },
    getnonoccupied(clientId) {
        return axios.get(this.allurl + clientId + "&limit=1000")
    },
    getpropertydocs(propertyId) {
        return axios.get(this.getpropertydocsurl + propertyId + "&limit=1000")
    },
    getbyId(id) {
        return axios.get(this.geturl + id);
    },
    add(tenant) {
        return axios.post(this.addurl, tenant);
    },
    update(tenant) {
        return axios.put(this.updateurl, tenant);
    },
    deleteTenant(id) {
        return axios.delete(this.deleteurl + id);
    },
    getTenantById(id) {
        return axios.get(this.getTenantUrl + id)
    },
    getPmsTenants(filter) {
        return axios.get(this.getPmsTenantsUrl + filter.tenantType + "&contactId=" + filter.contactId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    }


}