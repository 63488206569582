<template>
  <div class="container-fluid p-2 p-md-0 main-wrapper" style="height: 100vh; overflow: hidden" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
    <div class="row login-wrapper h-100  justify-content-center align-items-center">
      <div class="col-12 col-md-4 bg-white py-4 shadow  rounded text-center">
        <div class="mb-3">
          <h3 class="fw-bold">Forgotten Password ?</h3>
          <span>Enter your username below to request new password.</span>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center py-3">
          <div class="w-100 px-3">
            <div class="text-start">
              <label class="required">Username</label>
              <el-input
                  v-model="username"
                  class="inpt-bg w-100"
                  size="large"
                  tabindex="1"
                  
              ></el-input>
            </div>
            <div class="mt-5">
              <el-button
                  :disabled="loading || (!username)"
                  :loading="loading"
                  class="mbtn-primary rounded-1 w-100"
                  size="large"
                  @click="resetPassword"
              >{{ loading ? "Requesting" : "Request New Password" }}
              </el-button
              >
            </div>
            <el-divider>OR</el-divider>
            <el-form-item>
              <router-link to="/" class="w-100">
                <el-button bg class="btn btn-light w-100" size="large" text>
                  <span class="ms-2">Login</span>
                </el-button>
              </router-link>
            </el-form-item>
          </div>
        </div>

      </div>
    </div>
    <div v-if="loading" class="spinner-border signup-loader"></div>
  </div>
<!--    <div class="container-fluid p-2 p-md-0"  style="height: 100vh" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">-->
  <!--      <div class="row h-100">-->


  <!--        <div class="col-12 col-md-6 bg-white text-center sign-in-col">-->
  <!--          <div class="mb-3">-->
  <!--            <h3 class="fw-bold">Forgotten Password ?</h3>-->
  <!--            <span>Enter your username below to request new password.</span>-->
  <!--          </div>-->
  <!--          <div class="col-12 col-md-7 m-0 p-0">-->
  <!--            <el-form>-->
  <!--              <el-form-item>-->
  <!--                <label class="required">Username</label>-->
  <!--                <el-input-->
  <!--                    v-model="username"-->
  <!--                    class="inpt-bg"-->
  <!--                    size="large"-->
  <!--                    tabindex="1"-->
  <!--                ></el-input>-->
  <!--              </el-form-item>-->
  <!--              <el-form-item class="mt-5">-->
  <!--                <el-button-->
  <!--                    :disabled="loading || (!username)"-->
  <!--                    :loading="loading"-->
  <!--                    class="mbtn-primary rounded-1 w-100"-->
  <!--                    size="large"-->
  <!--                    @click="resetPassword"-->
  <!--                >{{ loading ? "Requesting" : "Request New Password" }}-->
  <!--                </el-button-->
  <!--                >-->
  <!--              </el-form-item>-->
  <!--              <el-divider>OR</el-divider>-->
  <!--              <el-form-item>-->
  <!--                <router-link to="/" class="w-100">-->
  <!--                  <el-button bg class="btn btn-light w-100" size="large" text>-->
  <!--                    <span class="ms-2">Login</span>-->
  <!--                  </el-button>-->
  <!--                </router-link>-->
  <!--              </el-form-item>-->
  <!--            </el-form>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    <div v-if="loading" class="spinner-border"></div>-->
  <!--  </div>-->
</template>
<script>
import loginServices from "@/Services/loginServices";
import {ShowMessage} from "@/Utility/Utility";
import configService from "@/Services/ComponentsServices/configService";

export default {
  name: "ForgotPassword",
  data() {
    return {
      imageUrl: "",
      loading: false,
      username: "",
    }
  },
  methods: {
    async resetPassword() {
      try {
        this.loading = true;
        let response = await loginServices.resetPassword(this.username);
       if(response.status == 200){
        ShowMessage("success", "Password sent to your email.");
       }
       else {
         ShowMessage("error", "Something went wrong");
       }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        ShowMessage("error", "Something went wrong");
        console.log(e.message)
      }
    }
  },
  created() {
    configService.getByKey(0, "LoginBackground").then((res) => {
      this.imageUrl = res.data.value;
    })
  }
}
</script>

<style scoped>
.main-wrapper{
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>